const NAME_MAX_LENGTH = 30;
const PHONE_MAX_LENGTH = 30;
const EMAIL_MAX_LENGTH = 128;
export const SPEED_MAX_NUMBER = 999;

export const validateName = (value) => value.length > 0 && value.length <= NAME_MAX_LENGTH;

export const validateEmail = (value) => value.length > 0 && value.length <= EMAIL_MAX_LENGTH;

export const validatePhone = (value) => /^\d+$/.test(value) && value.length <= PHONE_MAX_LENGTH;
