import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  debounce,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { messagesActions } from '../../store';
import { useTranslation } from '../../common/components/LocalizationProvider';
import PageLayout from '../../common/components/PageLayout';
import SettingsMenu from '../components/SettingsMenu';
import SearchHeader from '../components/SearchHeader';
import { useCatch, useEffectAsync } from '../../reactHelper';
import TableShimmer from '../../common/components/TableShimmer';
import { prefixString } from '../../common/util/stringUtils';
import { formatTime } from '../../common/util/formatter';
import SupportTicketDialog from '../components/support/SupportTicketDialog';
import CollectionActions from '../components/CollectionActions';
import ReportFilter from '../components/ReportFilter';
import periodConverter from '../../common/util/periodConverter';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 2),
  },
}));

const SupportPage = () => {
  const t = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [from, setFrom] = useState(periodConverter('today').selectedFrom.toISOString());
  const [to, setTo] = useState(periodConverter('today').selectedTo.toISOString());

  const handleSearch = useCallback(debounce(() => {
    setTimestamp(Date.now());
  }, 500), []);

  const handleSearchKeywordOnChange = (value) => {
    setSearchKeyword(value);
    handleSearch(value);
  };

  const handleEdit = (id) => {
    setSelectedItem(items.find((item) => item.id === id));
  };

  const handleSave = useCatch(async (ticket) => {
    const data = {
      ticketNo: ticket.ticketNo,
      deviceId: ticket.deviceId,
      type: ticket.type,
      status: ticket.status,
      content: ticket.content,
      userId: ticket.userId,
      createTime: ticket.createTime,
      processContent: ticket.processContent,
    };
    const response = await fetch(`/api/management/support-tickets/${ticket.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      dispatch(messagesActions.push(t('sharedSave')));
      setTimestamp(Date.now());
      setSelectedItem(null);
    } else {
      throw Error(await response.text());
    }
  });

  const handleSubmit = useCatch(async ({ from, to, type }) => {
    if (type === 'export') {
      const periodQuery = new URLSearchParams({ from, to });
      window.location.assign(`/api/management/support-tickets/xlsx?${periodQuery.toString()}`);
    } else {
      setFrom(from);
      setTo(to);
      setTimestamp(Date.now());
    }
  });

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const periodQuery = new URLSearchParams({ from, to });
      const response = await fetch(`/api/management/support-tickets?q=${searchKeyword}&page=${page + 1}&size=${rowsPerPage}&${periodQuery.toString()}`);
      if (response.ok) {
        const responseJson = await response.json();
        setItems(responseJson.data.items);
        setTotalCount(responseJson.data.totalCount);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp, page, rowsPerPage]);

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'supportService']}>
      <SearchHeader
        keyword={searchKeyword}
        onChange={handleSearchKeywordOnChange}
        alwaysShow
      />
      <div className={classes.header}>
        <ReportFilter
          ignoreDevice
          showOnly={false}
          handleSubmit={handleSubmit}
        />
      </div>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t('supportTicketNo')}</TableCell>
              <TableCell>{t('sharedType')}</TableCell>
              <TableCell>{t('sharedStatus')}</TableCell>
              <TableCell>{t('sharedLastUpdateTime')}</TableCell>
              <TableCell className={classes.columnAction} />
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? items.map((item) => (
              <TableRow key={item.id} hover>
                <TableCell>{item.ticketNo}</TableCell>
                <TableCell>{t(prefixString('supportTicketType', item.type))}</TableCell>
                <TableCell>{t(prefixString('supportTicketStatus', item.status))}</TableCell>
                <TableCell>{formatTime(item.lastUpdateTime)}</TableCell>
                <TableCell className={classes.columnAction}>
                  <CollectionActions
                    itemId={item.id}
                    itemName={item.ticketNo}
                    itemTypeName={t('supportTicket')}
                    endpoint="/management/support-tickets"
                    setTimestamp={setTimestamp}
                    onEdit={handleEdit}
                  />
                </TableCell>
              </TableRow>
            )) : (<TableShimmer columns={2} endAction />)}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage={t('labelRowsPerPage')}
          labelDisplayedRows={({ from, to, count }) => t('labelDisplayedRows', { from, to, count })}
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => { setPage(newPage); }}
          onRowsPerPageChange={(e) => {
            setRowPerPage(parseInt(e.target.value, 10));
            setPage(0);
          }}
        />
      </TableContainer>
      <SupportTicketDialog
        open={!!selectedItem}
        onClose={() => setSelectedItem(false)}
        ticket={selectedItem}
        setTicket={setSelectedItem}
        onSave={handleSave}
      />
    </PageLayout>
  );
};

export default SupportPage;
