import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Button, TextField,
} from '@mui/material';
import AccountMenu from './components/AccountMenu';
import PageLayout from '../common/components/PageLayout';
import CardLayout from '../common/components/CardLayout';
import CardSection from '../common/components/CardSection';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useCatch, useEffectAsync } from '../reactHelper';
import { groupsActions } from '../store';

const GroupsEditPage = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [item, setItem] = useState({ name: '' });
  const [errMsg, setErrMsg] = useState(' ');

  useEffect(() => {
    setErrMsg(' ');
  }, []);

  useEffectAsync(async () => {
    const response = await fetch(`/api/groups/${id}`);
    if (response.ok) {
      setItem(await response.json());
    } else {
      throw Error(await response.text());
    }
  }, [id]);

  const onItemSaved = useCatch(async () => {
    const response = await fetch('/api/groups?all=true');
    if (response.ok) {
      const result = await response.json();
      dispatch(groupsActions.update(result));
    } else {
      throw Error(await response.text());
    }
  });

  const checkInputText = (input) => {
    if (input.length < 1 || input.length > 40) {
      setErrMsg(t('inputTextLengthLimitType', { minLength: 1, maxLength: 40 }));
      return true;
    }
    return false;
  };

  const handleSave = useCatch(async () => {
    const isFail = checkInputText(item.name);
    if (!isFail) {
      const url = `/api/groups/${id}`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(item),
      });

      if (response.ok) {
        if (onItemSaved) {
          onItemSaved(await response.json());
        }
        navigate(-1);
      } else {
        throw Error(await response.text());
      }
    }
  });

  return (
    <PageLayout menu={<AccountMenu />} breadcrumbs={['settingsUser', 'deviceTitle']}>
      <CardLayout>
        <CardSection
          navIcon={<ArrowBackIcon />}
          header={t('groupEditName')}
          button={(
            <Button
              fullWidth
              variant="contained"
              color="containedButtonGreen2"
              onClick={handleSave}
              disabled={errMsg !== ' '}
            >
              {t('saveChange')}
            </Button>
          )}
        >
          <TextField
            error={errMsg !== ' '}
            variant="filled"
            color="filledTextFieldGreen"
            label={t('groupName')}
            value={item.name || ''}
            helperText={errMsg}
            onChange={(event) => {
              setItem({ ...item, name: event.target.value });
              setErrMsg(' ');
            }}
          />
        </CardSection>
      </CardLayout>
    </PageLayout>
  );
};

export default GroupsEditPage;
