import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AtrackLogoImage from './AtrackLogoImage';
import VersionInfo from './VersionInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  header: {
    backgroundColor: theme.palette.colors.header,
    height: '3.75rem',
    display: 'flex',
    alignItems: 'center',
  },
}));

const MainLayout = ({ header = true, children, style }) => {
  const classes = useStyles();

  return (
    <main className={classes.root} style={style}>
      {header && (
        <div className={classes.header}>
          <AtrackLogoImage />
        </div>
      )}
      <VersionInfo />
      {children}
    </main>
  );
};

export default MainLayout;
