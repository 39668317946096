import React, { useState, useEffect, useRef } from 'react';
import {
  Button, TextField, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Editor } from '@tinymce/tinymce-react';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { useCatch, useEffectAsync } from '../../reactHelper';
import { formatTime } from '../../common/util/formatter';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '100%',
    maxWidth: '1000px',
  },
  container: {
    display: 'inline-flex',
    alignItems: 'flex-center',
    padding: theme.spacing(3, 5, 1),
    gap: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(0, 5, 1),
    height: '1400px',
  },
  datetimeField: {
    '& .MuiInputBase-root': {
      height: '3.5rem',
      paddingBottom: '0.5rem',
    },
    '& .MuiInputLabel-root': {
      marginTop: '-0.5rem',
    },
  },
  buttonColor: {
    color: '#2ECD77',
  },
  dropzoneAreaPreviewContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    height: '100%',
    backgroundColor: theme.palette.colors.background,
  },
  dropzoneAreaPreviewItem: {
    flexBasis: 'unset',
    maxWidth: 'none',
    width: '90%',
    height: '90%',
    '& img': {
      width: '100%',
      height: '100%',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
      },
    },
  },
}));

const AddNewsView = ({
  open, id, isEdit, setTimestamp, onClose,
}) => {
  const API_KEY = process.env.REACT_APP_TINYMCE_API_KEY; // the api key use in [TinyMCE cloud version] for HTML editor
  const t = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();
  const [newsItem, setNewsItem] = useState({});
  const [dateErrorMsg, setDateErrorMsg] = useState('');
  const [contentErrorMsg, setContentErrorMsg] = useState('');
  const [isTitleEmpty, setIsTitleEmpty] = useState(false);
  const [isStartTimeEmpty, setIsStartTimeEmpty] = useState(false);
  const [openImageUpload, setOpenImageUpload] = useState(false);
  const [imageLink, setImageLink] = useState('');

  const editorRef = useRef(null);

  useEffect(() => {
    if (!isEdit) {
      setNewsItem({});
    }
    // initialize the error message
    setIsTitleEmpty(false);
    setIsStartTimeEmpty(false);
    setDateErrorMsg('');
    setContentErrorMsg('');
  }, [open]);

  useEffectAsync(async () => {
    if (isEdit) {
      const response = await fetch(`/api/management/news/${id}`);
      if (response.ok) {
        const getNews = await response.json();
        setNewsItem(getNews.data);
      } else {
        throw Error(await response.text());
      }
    }
  }, [isEdit]);

  const handleClose = () => {
    setOpenImageUpload(false);
  };

  const insertImage = () => {
    if (editorRef.current && imageLink !== '') {
      editorRef.current.insertContent(`<img src="${imageLink}" alt="Image can not be render" />`);
      handleClose();
      setImageLink('');
    }
  };

  const initEditor = (editor) => {
    // 禁用拖動圖片進入編輯器
    editor.on('dragover', (e) => {
      e.preventDefault();
    });

    // 禁用圖片放入編輯器
    editor.on('drop', (e) => {
      e.preventDefault();
    });
  };

  const handleEditorChange = (content) => {
    setNewsItem({ ...newsItem, content });
  };

  const checkInputStatus = () => {
    let isFail = false;
    if (!newsItem.hasOwnProperty('title') || newsItem.title === '') {
      setIsTitleEmpty(true);
      isFail = true;
    }
    if (!newsItem.hasOwnProperty('startTime') || newsItem.startTime === '') {
      setIsStartTimeEmpty(true);
      isFail = true;
    }
    if (!newsItem.hasOwnProperty('endTime') || newsItem.endTime === '') {
      setDateErrorMsg('End Time can not be null');
      isFail = true;
    }
    if (newsItem.startTime > newsItem.endTime) {
      setDateErrorMsg('End Time can not be earlier than Start Time');
      isFail = true;
    }
    if (!newsItem.hasOwnProperty('content') || newsItem.content === '') {
      setContentErrorMsg('Content can not be null');
      isFail = true;
    }
    if (newsItem.content?.length > 4194000) {
      // tc_news.content column size is [mediumtext] ( input text size should less than [16777215 / 4] bytes)
      setContentErrorMsg('Content size is too large');
      isFail = true;
    }
    return isFail;
  };

  const handleSave = useCatch(async () => {
    const isFail = checkInputStatus();
    if (!isFail) {
      const url = '/api/management/news';
      const response = await fetch(url, {
        method: isEdit ? 'PUT' : 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newsItem),
      });
      if (response.ok) {
        setTimestamp(Date.now());
        onClose();
      } else {
        throw Error(await response.text());
      }
    }
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <div className={classes.container}>
        <Typography sx={{ fontWeight: 'bold', fontFamily: 'Roboto', fontSize: '18px', marginTop: '20px' }}>{t('newsHead')}</Typography>
        <TextField
          margin="dense"
          name="title"
          type="title"
          variant="filled"
          color="filledTextFieldGreen"
          value={newsItem.title || ''}
          onChange={(e) => setNewsItem({ ...newsItem, title: e.target.value })}
          sx={{
            width: '90%',
          }}
          error={isTitleEmpty}
          helperText={isTitleEmpty ? 'Title can not be null' : ' '}
          required
        />
      </div>
      <div className={classes.container}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontFamily: 'Roboto', fontSize: '18px', marginTop: '20px' }}>{t('newsValidatePeriod')}</Typography>
        <div className={classes.filterItem}>
          <TextField
            className={classes.datetimeField}
            variant="filled"
            color="filledTextFieldGreen"
            label={t('reportFrom')}
            type="date"
            value={newsItem.startTime ? formatTime(newsItem.startTime, 'date') : ''}
            onChange={(e) => setNewsItem({ ...newsItem, startTime: e.target.value })}
            error={isStartTimeEmpty}
            helperText={isStartTimeEmpty ? 'Start Time can not be null' : ' '}
            fullWidth
          />
        </div>
        <div>
          <Typography variant="subtitle1" sx={{ marginTop: '20px' }}>
            ~
          </Typography>
        </div>
        <div className={classes.filterItem}>
          <TextField
            className={classes.datetimeField}
            variant="filled"
            color="filledTextFieldGreen"
            label={t('reportTo')}
            type="date"
            value={newsItem.endTime ? formatTime(newsItem.endTime, 'date') : ''}
            onChange={(e) => setNewsItem({ ...newsItem, endTime: e.target.value })}
            error={dateErrorMsg !== ''}
            helperText={dateErrorMsg !== '' ? dateErrorMsg : ' '}
            fullWidth
          />
        </div>
      </div>
      <div className={classes.container}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontFamily: 'Roboto', fontSize: '18px', marginTop: '20px' }}>{t('newsContent')}</Typography>
        {contentErrorMsg !== '' && <Typography color="error" variant="subtitle1" sx={{ fontWeight: 'bold', fontFamily: 'Roboto', fontSize: '18px', marginTop: '20px' }}>{contentErrorMsg}</Typography>}
      </div>
      <div className={classes.content}>
        <Editor
          apiKey={API_KEY}
          value={newsItem?.content ?? ''}
          onInit={(evt, editor) => editorRef.current = editor}
          init={{
            menubar: false,
            statusbar: false,
            images_file_types: 'jpg,jpeg,png,gif,bmp,webp',
            block_unsupported_drop: true,
            link_default_protocol: 'http',
            plugins: [
              'advlist',
              'autolink',
              'link',
              'lists',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'help',
              'wordcount',
              'paste',
            ],
            paste_preprocess(plugin, args) {
              // prevent if copy image file from local
              if (args.content.includes('<img src="')) {
                args.content = '';
              }
            },
            toolbar:
              'undo redo | blocks fontfamily fontsize | bold italic backcolor underline strikethrough | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap',
            setup: initEditor,
            tinycomments_mode: 'embedded',
            tinycomments_author: 'Author name',
            mergetags_list: [
              { value: 'First.Name', title: 'First Name' },
              { value: 'Email', title: 'Email' },
            ],
            ai_request: (request, respondWith) => respondWith.string(() => Promise.reject(new Error('ee docs to implement AI Assistant'))),
          }}
          onEditorChange={handleEditorChange}
        />
        <Button variant="outlined" onClick={() => setOpenImageUpload(true)}>
          {t('insertNewsImage')}
        </Button>
        <Dialog
          open={openImageUpload}
          onClose={handleClose}
          PaperProps={{
            className: classes.dialog,
          }}
        >
          <DialogContent>
            <DialogContentText>
              {t('insertNewsImageDescription')}
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              name="imageLink"
              label="Image Link"
              type="email"
              fullWidth
              variant="standard"
              onChange={(e) => setImageLink(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('quitCancel')}</Button>
            <Button type="submit" onClick={insertImage}>{t('shareSave')}</Button>
          </DialogActions>
        </Dialog>
      </div>
      <DialogActions>
        <Button onClick={handleSave} className={classes.buttonColor}>{t('shareSave')}</Button>
        <Button onClick={onClose} className={classes.buttonColor}>{t('quitCancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewsView;
