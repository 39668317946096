import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TextField, Button,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { DropzoneArea } from 'react-mui-dropzone';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useCatch, useEffectAsync } from '../reactHelper';
import PageLayout from '../common/components/PageLayout';
import AccountMenu from './components/AccountMenu';
import CardLayout from '../common/components/CardLayout';
import CardSection from '../common/components/CardSection';

const useStyles = makeStyles((theme) => ({
  dropzoneAreaPreviewContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    height: '100%',
    backgroundColor: theme.palette.colors.background,
  },
  dropzoneAreaPreviewItem: {
    flexBasis: 'unset',
    maxWidth: 'none',
    width: '90%',
    height: '90%',
    '& img': {
      width: '100%',
      height: '100%',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
      },
    },
  },
}));

const DevicePage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const navigate = useNavigate();
  const [item, setItem] = useState();
  const { id } = useParams();
  const [uploadDeviceImage, setUploadDeviceImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [deviceNameCheck, setDeviceNameCheck] = useState('');

  useEffectAsync(async () => {
    if (!item) {
      if (id) {
        const response = await fetch(`/api/devices/${id}`);
        if (response.ok) {
          setItem(await response.json());
        } else {
          throw Error(await response.text());
        }
      } else {
        setItem({});
      }
    }
  }, [id, item]);

  const getDeviceImagePath = item?.attributes?.deviceImage ? [`/api/media/${item.uniqueId}/${item.attributes.deviceImage}`] : [];

  const handleFileChange = (files) => {
    if (initialized) {
      if (files.length > 0) {
        setUploadDeviceImage(files[0]);
      } else {
        setUploadDeviceImage(null);
        const attributes = { ...item.attributes };
        delete attributes.deviceImage;
        setItem({ ...item, attributes: { ...attributes } });
      }
    }
    setInitialized(true);
  };

  const handleUploadDeviceImage = async (file) => {
    if (file) {
      const response = await fetch(`/api/devices/${item.id}/image`, {
        method: 'POST',
        body: file,
      });
      const responseJson = await response.json();
      if (response.ok && responseJson.data) {
        return responseJson.data.image;
      }
      throw Error(await responseJson.message);
    }
    return null;
  };

  const checkInputText = (input) => {
    if (input.length < 1 || input.length > 40) {
      setDeviceNameCheck(t('inputTextLengthLimitType', { minLength: 1, maxLength: 40 }));
      return true;
    }
    return false;
  };

  const handleSave = useCatch(async () => {
    setLoading(true);
    if (uploadDeviceImage) {
      const image = await handleUploadDeviceImage(uploadDeviceImage);
      item.attributes = { ...item.attributes, deviceImage: image };
    }
    const isFail = checkInputText(item.name);
    if (!isFail) {
      const response = await fetch(`/api/devices/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(item),
      });

      if (response.ok) {
        navigate(-1);
      } else {
        throw Error(await response.text());
      }
    }
    setLoading(false);
  });

  const validate = () => Object.keys(item).length !== 0 && item.name.length !== 0 && deviceNameCheck === '';

  return (
    <PageLayout menu={<AccountMenu />} breadcrumbs={['settingsUser', 'sharedDevice']} loading={loading}>
      { item && (
        <CardLayout disableDivider>
          <CardSection
            navIcon={<ArrowBack />}
            header={t('editDeviceTitle')}
          >
            <TextField
              error={deviceNameCheck !== ''}
              variant="filled"
              color="filledTextFieldGreen"
              value={item.name || ''}
              onChange={(event) => {
                setItem({ ...item, name: event.target.value });
                setDeviceNameCheck('');
              }}
              helperText={deviceNameCheck !== '' ? deviceNameCheck : ''}
              label={t('deviceNameTitle')}
            />
          </CardSection>
          <CardSection
            contentSx={{ '&&': { paddingTop: 0 } }}
            header={t('attributeDeviceImage')}
            description={t('uploadImageHint')}
            descriptionSx={{ '&&': { margin: 0 } }}
            button={(
              <Button
                fullWidth
                color="containedButtonGreen2"
                variant="contained"
                onClick={handleSave}
                disabled={!item || !validate() || loading}
              >
                {t('shareSave')}
              </Button>
            )}
            buttonSx={{ marginTop: '1rem' }}
          >
            <DropzoneArea
              initialFiles={getDeviceImagePath}
              dropzoneText={t('shareDeviceImage')}
              acceptedFiles={['image/*']}
              filesLimit={1}
              onChange={handleFileChange}
              // showPreviews
              // showPreviewsInDropzone={false}
              // useChipsForPreview
              previewGridProps={{ container: { spacing: 0 } }}
              previewChipProps={{ classes: { root: classes.previewChip } }}
              previewText={t('sharedSelectedFile')}
              previewGridClasses={{
                container: classes.dropzoneAreaPreviewContainer,
                item: classes.dropzoneAreaPreviewItem,
              }}
            />
          </CardSection>
        </CardLayout>
      )}
    </PageLayout>
  );
};

export default DevicePage;
