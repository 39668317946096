import { grey } from '@mui/material/colors';
import palette from './palette';

export default {
  MuiUseMediaQuery: {
    defaultProps: {
      noSsr: true,
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: grey[50],
      },
    },
  },
  // disable input autocomplete color: https://github.com/mui/material-ui/issues/14427#issuecomment-466054906
  MuiInputBase: {
    styleOverrides: {
      root: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
  },
  // https://mui.com/material-ui/customization/theme-components/#theme-style-overrides
  // custom variants support list: https://github.com/mui/material-ui/issues/21749
  MuiButtonGroup: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        '& .MuiButtonGroup-grouped:not(:last-of-type)': {
          '&:hover, :active, :focus': {
            borderRightColor: palette().colors.secondary,
          },
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ ownerState }) => ({
        height: '3.5rem',
        boxShadow: 'none',
        fontSize: '1.125rem',
        borderRadius: '0.5rem',
        fontWeight: 400,
        textTransform: 'none',
        '&:hover, :active, :focus': {
          boxShadow: 'none',
        },
        ...((ownerState.variant === 'contained') && {
          border: 'none',
          ...(ownerState.color === 'containedButtonGreen' && {
            '&:disabled': {
              color: palette().containedButtonGreen.contrastText,
              backgroundColor: palette().containedButtonGreen.main,
              opacity: 0.5,
            },
            '&:focus': {
              backgroundColor: palette().containedButtonGreen.dark,
            },
          }),
          ...(ownerState.color === 'containedButtonGreen2' && {
            '&:disabled': {
              backgroundColor: palette().containedButtonGreen2.dark,
              opacity: 0.5,
            },
            '&:focus': {
              backgroundColor: palette().containedButtonGreen2.dark,
            },
          }),
          ...(ownerState.color === 'containedButtonGrey' && {
            '&:disabled': {
              backgroundColor: palette().containedButtonGrey.dark,
              opacity: 0.5,
            },
            '&:focus': {
              backgroundColor: palette().containedButtonGrey.dark,
            },
          }),
          ...(ownerState.color === 'containedButtonBlue' && {
            '&:disabled': {
              backgroundColor: palette().containedButtonBlue.dark,
              opacity: 0.5,
            },
            '&:focus': {
              backgroundColor: palette().containedButtonBlue.dark,
            },
          }),
        }),
        ...((ownerState.variant === 'outlined') && {
          border: '2px solid',
          ...(ownerState.color === 'outlinedButtonGreen' && {
            borderColor: palette().colors.primary,
            '&:hover, :active, :focus': {
              border: '2px solid',
              borderColor: palette().colors.secondary,
              backgroundColor: palette().outlinedButtonGreen.hover,
            },
            '&:disabled': {
              border: '2px solid',
              borderColor: palette().outlinedButtonGreen.disable,
              color: palette().text.hint,
              opacity: 0.5,
            },
          }),
        }),
        ...((ownerState.variant === 'text') && {
          fontSize: '1rem',
          color: palette().text.primary,
          fontWeight: 400,
          '&:hover, :active': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
            fontWeight: 700,
          },
          '&:disabled': {
            textDecoration: 'underline',
            fontWeight: 700,
            opacity: 0.5,
          },
        }),
        '.MuiButton-startIcon': {
          color: palette().text.primary,
        },
      }),
    },
  },
  MuiFormControl: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiSnackbar: {
    defaultProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...((ownerState.variant === 'filled') && {
          '& .MuiInputBase-root:not(.MuiInputBase-multiline)': {
            height: '3.5rem',
          },
          '& .MuiInputBase-root, .MuiFilledInput-input': {
            backgroundColor: 'transparent',
            '&::-ms-reveal': {
              display: 'none', // disable Edge password reveal
            },
            '&:hover, &:focus, &.Mui-focused, &.Mui-disabled': {
              backgroundColor: 'transparent',
              '&:before': {
                borderBottomStyle: 'solid',
              },
            },
          },
          '& .MuiInputLabel-shrink': {
            lineHeight: '1rem',
          },
          '& .MuiFilledInput-underline:after': {
            // disable underline animation
            display: 'none',
          },
          '& .MuiFilledInput-underline:before, .MuiFilledInput-underline:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottomWidth: '2px',
          },
          '& .MuiFilledInput-underline:has(.Mui-disabled):before': {
            borderBottomColor: palette().text.disable,
          },
          // Filled TextField - White
          ...(ownerState.color === 'filledTextFieldWhite' && {
            // input text
            '& .MuiFilledInput-root': {
              color: palette().filledTextFieldWhite.main,
            },
            // placeholder text
            '& .MuiInputLabel-filled:not(.Mui-disabled, .Mui-error)': {
              color: palette().filledTextFieldWhite.main,
            },
            // input underline
            '& .MuiFilledInput-underline:not(.Mui-disabled, .Mui-error):before, .MuiFilledInput-underline:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottomColor: palette().filledTextFieldWhite.main,
            },
          }),
          // Filled TextField - Green
          ...(ownerState.color === 'filledTextFieldGreen' && {
            // input text
            '& .MuiFilledInput-root': {
              color: palette().text.primary,
            },
            // placeholder text
            '& .MuiInputLabel-filled:not(.Mui-disabled, .Mui-error)': {
              color: palette().text.primary,
            },
            // input state label
            '& .MuiInputLabel-shrink:not(.Mui-disabled, .Mui-error)': {
              color: palette().filledTextFieldGreen.main,
            },
            // input underline
            '& .MuiFilledInput-underline:not(.Mui-disabled, .Mui-error):before, .MuiFilledInput-underline:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottomColor: palette().filledTextFieldGreen.main,
            },
          }),
          // Filled TextField for login page
          ...(ownerState.color === 'filledTextFieldLogin' && {
            borderRadius: '0.25rem',
            '& .MuiInputBase-root, .MuiFilledInput-input': {
              backgroundColor: palette().colors.white,
              '&::-ms-reveal': {
                display: 'none', // disable Edge password reveal
              },
              '&:hover, &:focus, &.Mui-focused, &.Mui-disabled': {
                backgroundColor: palette().colors.white,
              },
            },
            // input text
            '& .MuiFilledInput-root': {
              color: palette().text.primary,
            },
            // placeholder text
            '& .MuiInputLabel-filled:not(.Mui-disabled, .Mui-error)': {
              color: palette().text.primary,
            },
            // input state label
            '& .MuiInputLabel-shrink:not(.Mui-disabled, .Mui-error)': {
              color: palette().filledTextFieldLogin.main,
            },
            // input underline
            '& .MuiFilledInput-underline:not(.Mui-disabled, .Mui-error):before, .MuiFilledInput-underline:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottomColor: palette().filledTextFieldLogin.main,
            },
          }),
          // Filled TextField - Blue
          ...(ownerState.color === 'filledTextFieldBlue' && {
            // input text
            '& .MuiFilledInput-root': {
              color: palette().text.primary,
            },
            // placeholder text
            '& .MuiInputLabel-filled:not(.Mui-disabled, .Mui-error)': {
              color: palette().text.primary,
            },
            // input state label
            '& .MuiInputLabel-shrink:not(.Mui-disabled, .Mui-error)': {
              color: palette().colors.white,
              backgroundColor: palette().filledTextFieldBlue.main,
              padding: '0.25rem 0.5rem',
              margin: '-0.25rem -0.25rem',
              borderRadius: '0.25rem',
            },
            // input underline
            '& .MuiFilledInput-underline:not(.Mui-disabled, .Mui-error):before, .MuiFilledInput-underline:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottomColor: palette().filledTextFieldBlue.main,
            },
          }),
        }),
      }),
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        color: palette().text.primary,
        ...((ownerState.variant === 'grey') && {
          color: palette().text.hint,
        }),
      }),
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: palette().colors.text.primary,
      },
    },
  },
  MuiListItemButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        borderRadius: '0.5rem',
        '&:hover': {
          backgroundColor: palette().colors.menu.inactive.hover,
        },
        '&.Mui-selected': {
          backgroundColor: palette().colors.menu.active.background,
          '&:hover': {
            backgroundColor: palette().colors.menu.active.hover,
          },
        },
        '.MuiListItemIcon-root': {
          color: palette().pageSidebar.text.default,
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        // color: '',
      },
      colorPrimary: {
        '&.Mui-checked': {
          // Controls checked color for the thumb
          color: palette().colors.switch.active,
        },
        '&.Mui-checked.Mui-disabled': {
          color: palette().colors.switch.activeDisabled,
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        // opacity: 0.2,
        // backgroundColor: '',
        '.Mui-checked.Mui-checked + &': {
          // Controls checked color for the track
          opacity: 0.5,
          backgroundColor: palette().colors.switch.active,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        fontWeight: 500,
        fontSize: '0.875rem',
        border: '1px solid',
        ...((ownerState.variant === 'transparent') && {
          border: 'none',
          backgroundColor: 'transparent',
          color: palette().text.hint,
          '&:hover': {
            backgroundColor: palette().background.default,
          },
        }),
        ...((ownerState.variant !== 'outlined') && {
          border: 'none',
        }),
      }),
    },
  },
  MuiBottomNavigationAction: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        fontWeight: 500,
        '& .MuiSvgIcon-root': {
          width: '4rem',
          height: '2rem',
          margin: '0.25rem 0',
          borderTop: '0.25rem solid',
          borderBottom: '0.25rem solid',
          borderColor: 'transparent',
        },
      },
    },
  },
};
