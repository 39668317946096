import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography, TextField,
  InputAdornment,
  Button, IconButton,
  FormControl, Snackbar,
  Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { createUserWithEmailAndPassword, sendEmailVerification, deleteUser } from 'firebase/auth';
import LoginLayout from './LoginLayout';
import { useTranslation } from '../common/components/LocalizationProvider';
import { snackBarDurationShortMs } from '../common/util/duration';
import { useCatch } from '../reactHelper';
import { sessionActions } from '../store';
import auth, { AUTH_PASSWORD_MAX_LENGTH, AUTH_PASSWORD_MIN_LENGTH } from '../firebase/auth';
import AlertDialog from '../common/components/AlertDialog';
import FormContainer from './FormContainer';
import ThirdPartyAuthContainer from './ThirdPartyAuthContainer';
import Policy from './Policy';
import countryCodes from '../resources/countryCodes.json';
import SelectField from '../common/components/SelectField';
import { validateEmail, validatePhone } from '../common/util/validator';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.75rem',
    fontWeight: 400,
    lineHeight: '2.25rem',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  gridPhone: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px',
  },
  registButton: {
    minWidth: '80px',
    minHeight: '30px',
    maxWidth: '30%',
    maxHeight: '10%',
  },
  inputPassword: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      flexBasis: '100%',
    },
  },
  policyContainer: {
    display: 'flex',
    marginTop: '3.25rem',
  },
}));

const RegisterPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation();

  const server = useSelector((state) => state.session.server);
  const googleLoginEnabled = server.googleLoginEnabled || true;
  const facebookLoginEnabled = server.facebookLoginEnabled || false;
  const appleLoginEnabled = server.appleLoginEnabled || true;

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const [countryCode, setCountryCode] = useState('');
  const [phone, setPhone] = useState('');
  const [snackbarOpen, setSnackBarOpen] = useState(false);

  const [isEmailSendModalOpen, setIsEmailSendModalOpen] = useState(false);

  const handleClickNewPassword = () => setShowPassword((show) => !show);
  const handleClickConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePhoneInputChange = (value) => {
    if (value === '' || validatePhone(value)) {
      setPhone(value);
    }
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    if (value === '' || validateEmail(value)) {
      setEmail(value);
      setEmailError(false);
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError(false);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setConfirmPasswordError(false);
  };

  const handleFirebaseRegister = async () => {
    try {
      const userCred = await createUserWithEmailAndPassword(auth, email, password);
      return userCred.user;
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setEmailError(true);
        setEmailErrorMessage(t('the email address is already in use'));
      } else if (error.code === 'auth/invalid-email') {
        setEmailError(true);
        setEmailErrorMessage(t('the email address is invalid'));
      } else if (error.code === 'auth/weak-password' || error.code === 'auth/password-does-not-meet-requirements') {
        // firebase-emulator throws 'auth/weak-password' when password is too short then 6 characters
        // firebase with identity platform throws 'auth/password-does-not-meet-requirements'
        setPasswordError(true);
        setPassword('');
        setConfirmPassword('');
      } else {
        throw Error();
      }
      return null;
    }
  };

  const handleSubmit = useCatch(async () => {
    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      setConfirmPassword('');
      return;
    }
    const user = await handleFirebaseRegister();
    if (user) {
      const idToken = await user.getIdToken();
      const response = await fetch('/api/users/firebase', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ countryCode, phone, idToken, name: '' }),
      });
      if (response.ok) {
        await sendEmailVerification(user);
        setIsEmailSendModalOpen(true);
      } else {
        await auth.signOut();
        await deleteUser(user);
        throw Error(await response.text());
      }
    }
  });

  const handle3rdPartyLogin = useCatch(async (provider) => {
    if (auth.currentUser) {
      await auth.signOut();
    }
    navigate(`/login/redirect?provider=${provider}`);
  });

  const handleClickOk = () => {
    setIsEmailSendModalOpen(false);
    setSnackBarOpen(true);
  };

  return (
    <LoginLayout>
      <AlertDialog
        isOpen={isEmailSendModalOpen}
        title={t('Please check your email for the verification message.')}
        content={t('Please check your registered email for the registration verification message and follow the instructinos to activate your account.')}
        agreeBtnText={t('modalButtonOk')}
        onAgree={handleClickOk}
      />
      <FormContainer
        header={
          <Typography className={classes.title}>{t('Please enter your information')}</Typography>
        }
        footer={(
          <>
            <ThirdPartyAuthContainer
              titleText={t('thirdPartyRegisterInfo')}
              googleLoginEnabled={googleLoginEnabled}
              facebookLoginEnabled={facebookLoginEnabled}
              appleLoginEnabled={appleLoginEnabled}
              handle3rdPartyLogin={handle3rdPartyLogin}
            />
            <div className={classes.policyContainer}>
              <Policy />
            </div>
          </>
        )}
      >
        <TextField
          variant="filled"
          color="filledTextFieldGreen"
          required
          type="email"
          label={t('email')}
          name="email"
          value={email}
          autoComplete="email"
          onChange={handleEmailChange}
          error={emailError}
          helperText={emailError ? emailErrorMessage : ''}
        />
        <Grid container className={classes.gridPhone}>
          <Grid item xs={5}>
            <SelectField
              fullWidth
              required
              label={t('countryCode')}
              value={countryCode || ''}
              onChange={(e) => setCountryCode(e.target.value)}
              color="filledTextFieldGreen"
              data={countryCodes}
              keyGetter={(item) => item.code}
              valueGetter={(item) => item.country_code}
              titleGetter={(item) => `${item.code} ${item.country_code}`}
              emptyValue={null}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <TextField
                variant="filled"
                color="filledTextFieldGreen"
                required
                label={t('sharedPhone')}
                value={phone}
                onChange={(event) => handlePhoneInputChange(event.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>
        <TextField
          className={classes.inputPassword}
          variant="filled"
          color="filledTextFieldGreen"
          required
          label={t('userPassword')}
          id="standard-adornment-password-1"
          value={password}
          type={showPassword ? 'text' : 'password'}
          onChange={handlePasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickNewPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  tabIndex={-1}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={passwordError}
          helperText={passwordError ? t('userPasswordPolicyHelperText', { minLength: AUTH_PASSWORD_MIN_LENGTH, maxLength: AUTH_PASSWORD_MAX_LENGTH }) : ''}
        />
        <TextField
          className={classes.inputPassword}
          variant="filled"
          color="filledTextFieldGreen"
          required
          label={t('confirmPassword')}
          id="standard-adornment-password-2"
          type={showConfirmPassword ? 'text' : 'password'}
          onChange={handleConfirmPasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  tabIndex={-1}
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={confirmPasswordError}
          helperText={confirmPasswordError ? t('wrong password') : ''}
        />
        <Grid container spacing={1} className={classes.gridItem}>
          <Grid item xs={2}>
            {!server.newServer && (
              <Button variant="contained" color="containedButtonGrey" onClick={() => navigate('/login')}>
                <ArrowBackIcon />
              </Button>
            )}
          </Grid>
          <Grid item xs={8} className={classes.registButton}>
            <Button
              variant="contained"
              color="containedButtonGreen"
              onClick={handleSubmit}
              // disabled={!name || !password || !(server.newServer || /(.+)@(.+)\.(.{2,})/.test(email))}
              disabled={!email || !countryCode || !phone || !password || !confirmPassword}
              fullWidth
            >
              {t('loginRegister')}
            </Button>
          </Grid>
        </Grid>
      </FormContainer>
      <Snackbar
        open={snackbarOpen}
        onClose={() => {
          dispatch(sessionActions.updateServer({ ...server, newServer: false }));
          navigate('/login', { state: { email } });
        }}
        autoHideDuration={snackBarDurationShortMs}
        message={t('loginCreated')}
      />
    </LoginLayout>
  );
};

export default RegisterPage;
