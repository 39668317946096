import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import {
  Paper, BottomNavigation, BottomNavigationAction, Menu, MenuItem, Typography, Badge,
} from '@mui/material';

// import HomeIcon from '@mui/icons-material/Home';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DescriptionIcon from '@mui/icons-material/Description';
// import SettingsIcon from '@mui/icons-material/Settings';
import MapIcon from '@mui/icons-material/Map';
import PersonIcon from '@mui/icons-material/Person';
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';

// import { sessionActions } from '../../store';
import { useTranslation } from './LocalizationProvider';
// import { useRestriction } from '../util/permissions';
// import { nativePostMessage } from './NativeInterface';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: theme.palette.mapSidebar.background,
  },
  navigation: {
    backgroundColor: theme.palette.mapSidebar.nav,
  },
  navigationAction: {
    borderRadius: '1rem',
    margin: '0.5rem 0.25rem',
    '&:first-child': {
      marginLeft: '0.5rem',
    },
    '&:last-child': {
      marginRight: '0.5rem',
    },
    color: theme.palette.mapSidebar.text.default,
    '&:hover': {
      backgroundColor: theme.palette.mapSidebar.button.hover,
      color: theme.palette.mapSidebar.text.hover,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.mapSidebar.button.active,
      color: theme.palette.mapSidebar.text.active,
      '&:hover': {
        backgroundColor: theme.palette.mapSidebar.button.activeHover,
        color: theme.palette.mapSidebar.text.activeHover,
      },
    },
  },
}));

const BottomMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useDispatch();
  const t = useTranslation();
  const classes = useStyles();

  // const readonly = useRestriction('readonly');
  // const disableReports = useRestriction('disableReports');
  const user = useSelector((state) => state.session.user);
  const socket = useSelector((state) => state.session.socket);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);

  const currentSelection = () => {
    if (location.pathname === `/settings/user/${user.id}`) {
      return 'account';
    } if (location.pathname.startsWith('/settings')) {
      return 'settings';
    } if (location.pathname.startsWith('/reports')) {
      return 'reports';
    } if (location.pathname.startsWith('/system')) {
      return 'system';
    } if (location.pathname === '/map' || location.pathname === '/') {
      return 'map';
    }
    // } if (location.pathname === '/') {
    //   return 'home';
    // }
    return null;
  };
  const handleGroup = () => {
    setAnchorEl(null);
    navigate('/accounts/groups');
  };

  const handleAccount = () => {
    setAnchorEl(null);
    navigate(`/accounts/info/${user.id}`);
  };

  const handleDevices = () => {
    setAnchorEl(null);
    navigate('/accounts/devices');
  };

  // const handleLogout = async () => {
  //   setAnchorEl(null);

  //   const notificationToken = window.localStorage.getItem('notificationToken');
  //   if (notificationToken && !user.readonly) {
  //     window.localStorage.removeItem('notificationToken');
  //     const tokens = user.attributes.notificationTokens?.split(',') || [];
  //     if (tokens.includes(notificationToken)) {
  //       const updatedUser = {
  //         ...user,
  //         attributes: {
  //           ...user.attributes,
  //           notificationTokens: tokens.length > 1 ? tokens.filter((it) => it !== notificationToken).join(',') : undefined,
  //         },
  //       };
  //       await fetch(`/api/users/${user.id}`, {
  //         method: 'PUT',
  //         headers: { 'Content-Type': 'application/json' },
  //         body: JSON.stringify(updatedUser),
  //       });
  //     }
  //   }

  //   await fetch('/api/session', { method: 'DELETE' });
  //   nativePostMessage('logout');
  //   navigate('/login');
  //   dispatch(sessionActions.updateUser(null));
  // };

  // const handleReports = () => {
  //   setAnchorE2(null);
  //   navigate('/reports/route');
  // };

  const handleSettings = () => {
    setAnchorE2(null);
    navigate('/settings/preferences');
  };

  const handleSystem = () => {
    setAnchorE2(null);
    navigate('/news');
  };

  const handleSelection = (event, value) => {
    switch (value) {
      case 'map':
        navigate('/');
        break;
      case 'account':
        setAnchorEl(event.currentTarget);
        break;
      case 'report':
        navigate('/reports/route');
        break;
      case 'more':
        setAnchorE2(event.currentTarget);
        break;
      default:
        break;
    }
  };

  return (
    <Paper square elevation={3} sx={{ borderRadius: '16px' }} className={classes.sidebar}>
      <BottomNavigation value={currentSelection()} onChange={handleSelection} showLabels sx={{ height: '5.25rem', borderRadius: '16px' }} className={classes.navigation}>
        <BottomNavigationAction
          label={t('mapTitle')}
          icon={(
            <Badge color="error" variant="dot" overlap="circular" invisible={socket !== false}>
              <MapIcon />
            </Badge>
          )}
          value="map"
          className={classes.navigationAction}
        />
        <BottomNavigationAction label={t('settingsUser')} icon={<PersonIcon />} value="account" className={classes.navigationAction} />
        <BottomNavigationAction label={t('reportTitle')} icon={<DescriptionIcon />} value="report" className={classes.navigationAction} />
        <BottomNavigationAction label={t('moreTitle')} icon={<MoreHorizIcon />} value="more" className={classes.navigationAction} />
      </BottomNavigation>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleGroup}>
          <Typography color="textPrimary">{t('groupTitle')}</Typography>
        </MenuItem>
        <MenuItem onClick={handleAccount}>
          <Typography color="textPrimary">{t('accountTitle')}</Typography>
        </MenuItem>
        <MenuItem onClick={handleDevices}>
          <Typography color="textPrimary">{t('manageResoueces')}</Typography>
        </MenuItem>
        {/* <MenuItem onClick={handleLogout}>
          <Typography color="error">{t('loginLogout')}</Typography>
        </MenuItem> */}
      </Menu>
      <Menu anchorEl={anchorE2} open={Boolean(anchorE2)} onClose={() => setAnchorE2(null)}>
        <MenuItem onClick={handleSettings}>
          <Typography color="textPrimary">{t('settingsTitle')}</Typography>
        </MenuItem>
        <MenuItem onClick={handleSystem}>
          <Typography color="textPrimary">{t('news')}</Typography>
        </MenuItem>
      </Menu>
    </Paper>
  );
};

export default BottomMenu;
