import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TextField, Button, Snackbar,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useCatch, useEffectAsync } from '../reactHelper';
import CardLayout from '../common/components/CardLayout';
import CardSection from '../common/components/CardSection';
import { useTranslation } from '../common/components/LocalizationProvider';
import AccountsMenu from './components/AccountMenu';
import PageLayout from '../common/components/PageLayout';
import { snackBarDurationShortMs } from '../common/util/duration';
import SelectField from '../common/components/SelectField';
import { validatePhone } from '../common/util/validator';

const EditDriverPage = () => {
  const t = useTranslation();
  const [deviceList, setDeviceList] = useState([]);
  const [driver, setDriver] = useState();
  const [open, setOpen] = useState(false);
  const [driverNameCheck, setDriverNameCheck] = useState('');

  const navigate = useNavigate();
  const { id } = useParams();

  useEffectAsync(async () => {
    const response = await fetch(`/api/drivers/${id}`);
    if (response.ok) {
      const driver = await response.json();
      setDriver(driver);
      if (driver) {
        const getDrivers = await fetch('/api/drivers');
        const getDevices = await fetch('/api/devices');
        if (getDrivers.ok) {
          const driverItems = await getDrivers.json();
          const deviceItems = await getDevices.json();
          // 移除已綁定的 devices 但不包含目前的 driver 綁定的 device
          const filteredDevices = deviceItems.filter((deviceItem) => driver.deviceId === deviceItem.id || (!driverItems.some((driverItem) => driverItem.deviceId === deviceItem.id)));
          setDeviceList(filteredDevices);
        } else {
          throw Error(await getDrivers.text());
        }
      }
    } else {
      throw Error(await response.text());
    }
  }, []);

  const checkInputText = (input) => {
    if (!input) {
      setDriverNameCheck(t('driverNameIsEmptyErrorMessage'));
      return true;
    }
    if (input.length < 1 || input.length > 40) {
      setDriverNameCheck(t('inputTextLengthLimitType', { minLength: 1, maxLength: 40 }));
      return true;
    }
    return false;
  };

  const handleSave = useCatch(async () => {
    const isFail = checkInputText(driver.name);
    if (!isFail) {
      const response = await fetch(`/api/drivers/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(driver),
      });
      if (response.ok) {
        setOpen(true);
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } else {
        throw Error(await response.text());
      }
    }
  });

  return (
    <PageLayout menu={<AccountsMenu />} breadcrumbs={['settingsUser', 'deviceTitle']}>
      <CardLayout>
        <CardSection
          navIcon={<ArrowBackIcon />}
          header={t('editDriverTitle')}
          button={(
            <Button
              fullWidth
              variant="contained"
              color="containedButtonGreen2"
              onClick={handleSave}
            >
              {t('shareSave')}
            </Button>
          )}
        >
          <TextField
            required
            variant="filled"
            color="filledTextFieldGreen"
            value={driver?.name || ''}
            error={driverNameCheck !== ''}
            helperText={driverNameCheck !== '' ? driverNameCheck : ' '}
            onChange={(event) => {
              setDriverNameCheck('');
              setDriver({ ...driver, name: event.target.value });
            }}
            label={t('driverName')}
          />
          <TextField
            variant="filled"
            color="filledTextFieldGreen"
            value={driver?.phone || ''}
            onChange={(event) => {
              const phone = event.target.value;
              if (phone === '' || validatePhone(phone)) {
                setDriver({ ...driver, phone });
              }
            }}
            helperText=" "
            label={t('driverPhone')}
          />
          <SelectField
            color="filledTextFieldGreen"
            label={t('deviceTitle')}
            value={deviceList.find((item) => item.id === driver?.deviceId)?.id || ''}
            helperText=" "
            onChange={(event) => {
              setDriver({ ...driver, deviceId: event.target.value });
            }}
            data={[
              { id: '', name: t('shareSelectNothing') },
              ...deviceList,
            ]}
            emptyValue={null}
          />
        </CardSection>
      </CardLayout>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={snackBarDurationShortMs}
        message={t('shareSaved')}
      />
    </PageLayout>
  );
};

export default EditDriverPage;
