import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  Divider, Drawer, IconButton, List, ListItemButton, ListItemText, Toolbar, Typography, Button, ListItem, Chip,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatNotificationTitle, formatTime } from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { eventsActions } from '../store';
import { usePreference } from '../common/util/preferences';
import EventsAccordion from './EventsAccordion';
import { useEffectAsync } from '../reactHelper';

const useStyles = makeStyles((theme) => ({
  listContainer: {
    overflow: 'auto',
    height: 'inherit',
  },
  list: {
    width: theme.dimensions.eventsDrawerWidth,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  toolbarContainer: {
    position: 'sticky',
    top: 0,
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    gap: theme.spacing(1),
  },
  footbarContainer: {
    position: 'sticky',
    bottom: 0,
  },
  footbar: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  footButton: {
    width: '95%',
  },
  title: {
    flexGrow: 1,
  },
  divider: {
    margin: '0 1.375rem',
  },
}));

const EventsDrawer = ({ open, onClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();

  const hours12 = usePreference('twelveHourFormat');

  const devices = useSelector((state) => ({ ...state.devices.items, ...state.devices.groupItems }));

  const events = useSelector((state) => state.events.items);

  const [eventsOfDevices, setEventsOfDevices] = useState({});

  const formatType = (event) => formatNotificationTitle(t, {
    type: event.type,
    attributes: {
      alarms: event.attributes.alarm,
    },
  });

  const handleRemoveAll = async () => {
    const response = await fetch('/api/event-notifications', {
      method: 'DELETE',
    });
    if (response.ok) {
      dispatch(eventsActions.deleteAll());
    } else {
      const responseJson = await response.json();
      throw Error(responseJson.message);
    }
  };

  const handleProcessButtonClick = async (clickEvent, event, eventNotificationId) => {
    clickEvent.stopPropagation();
    const response = await fetch(`/api/event-notifications/${eventNotificationId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ isProcessed: true }),
    });
    if (response.ok) {
      dispatch(eventsActions.filpIsProcessed(event));
    } else {
      const responseJson = await response.json();
      throw Error(responseJson.message);
    }
  };

  const getUnprocessedEventsCount = (deviceId) => eventsOfDevices[deviceId].filter((event) => !event.isProcessed).length;

  useEffect(() => {
    // group by deviceId
    const grouped = events.reduce((acc, event) => {
      if (!acc[event.deviceId]) {
        acc[event.deviceId] = [];
      }
      acc[event.deviceId].push(event);
      return acc;
    }, {});

    setEventsOfDevices(grouped);
  }, [events]);

  useEffectAsync(async () => {
    const from = moment().startOf('day').toISOString();
    const to = moment().endOf('day').toISOString();
    const query = new URLSearchParams({ from, to });
    const response = await fetch(`/api/event-notifications?${query.toString()}`);
    const responseJson = await response.json();
    if (response.ok) {
      const events = responseJson.data.reverse();
      dispatch(eventsActions.refresh(events));
    } else {
      throw Error(responseJson.message);
    }
  }, []);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      className={classes.drawer}
    >
      <div className={classes.toolbarContainer}>
        <Toolbar className={classes.toolbar} disableGutters>
          <IconButton onClick={onClose}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {t('eventNotificationTodaysEvents')}
          </Typography>
          <IconButton size="small" color="inherit" onClick={handleRemoveAll}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Toolbar>
        <Divider className={classes.divider} />
      </div>
      <div className={classes.listContainer}>
        <List className={classes.list} dense>
          {Object.keys(eventsOfDevices).map((deviceId) => (
            <EventsAccordion
              key={deviceId}
              summary={devices[deviceId]?.name}
              itemCount={getUnprocessedEventsCount(deviceId)}
            >
              {eventsOfDevices[deviceId].map((event) => (
                <ListItem key={event.id} disableGutters>
                  <ListItemButton
                    onClick={() => event.positionId && navigate(`/event/${event.id}`)}
                    disabled={!event.id}
                    style={{ cursor: event.positionId ? 'pointer' : 'default' }}
                  >
                    <ListItemText
                      primary={formatType(event)}
                      secondary={formatTime(event.eventTime, 'seconds', hours12)}
                      primaryTypographyProps={{ fontSize: '1rem' }}
                    />
                    { event.isProcessed ? (
                      <Chip
                        color="chipGreen"
                        label={t('eventNotificationProcessed')}
                      />
                    ) : (
                      <Chip
                        variant="transparent"
                        label={t('eventNotificationUnprocessed')}
                        onClick={(clickEvent) => handleProcessButtonClick(clickEvent, event, event.eventNotificationId)}
                      />
                    )}
                  </ListItemButton>
                </ListItem>
              ))}
            </EventsAccordion>
          ))}
        </List>
      </div>
      <div className={classes.footbarContainer}>
        <Toolbar className={classes.footbar} disableGutters>
          <Divider className={classes.divider} sx={{ width: 'inherit' }} />
          <Button
            variant="contained"
            color="containedButtonGreen2"
            className={classes.footButton}
            onClick={() => navigate('/reports/event')}
          >
            {t('eventNotificationAllEvents')}
          </Button>
        </Toolbar>
      </div>
    </Drawer>
  );
};

export default EventsDrawer;
