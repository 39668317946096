import React, { useState, useEffect } from 'react';
import {
  Button, TextField, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import useReportStyles from '../common/useReportStyles';
import { devicesActions, reportsActions } from '../../store';
import periodConverter from '../../common/util/periodConverter';
import SelectField from '../../common/components/SelectField';
import { prefixString } from '../../common/util/stringUtils';

const ReplayFilter = ({
  children, handleSubmit,
}) => {
  const classes = useReportStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);
  const groupDevices = useSelector((state) => state.devices.groupItems);

  const deviceId = useSelector((state) => state.devices.selectedId);
  const period = useSelector((state) => state.reports.period);
  const from = useSelector((state) => state.reports.from);
  const to = useSelector((state) => state.reports.to);

  const [allDevices, setAllDevices] = useState([]);

  const disabled = !deviceId;
  useEffect(() => {
    // account have only registered devices
    if (Object.keys(devices).length > 0) {
      setAllDevices(devices);
    }
    // account have only groups share devices
    if (Object.keys(groupDevices).length > 0) {
      setAllDevices(groupDevices);
    }
    // account have both registered devices and groups share devices
    if (Object.keys(devices).length > 0 && Object.keys(groupDevices).length > 0) {
      setAllDevices([...Object.values(devices), ...Object.values(groupDevices)]);
    }
  }, [devices, groupDevices]);

  const handleClick = (type) => {
    const seletecdPeriod = periodConverter(period, from, to);

    handleSubmit({
      deviceId,
      from: seletecdPeriod.selectedFrom.toISOString(),
      to: seletecdPeriod.selectedTo.toISOString(),
      type,
    });
  };

  return (
    <div className={classes.filter}>
      <div className={classes.filterItem}>
        <SelectField
          fullWidth
          color="filledTextFieldGreen"
          label={t('deviceTitle')}
          value={Object.values(allDevices).some((device) => device.id === deviceId) ? deviceId : ''}
          onChange={(e) => dispatch(devicesActions.selectId(e.target.value))}
          data={Object.values(allDevices).sort((a, b) => (a.name || '').localeCompare(b.name || ''))}
          keyGetter={(it) => it.id}
          titleGetter={(it) => it.name}
          emptyValue={null}
        />
      </div>
      <div className={classes.filterItem}>
        <SelectField
          fullWidth
          color="filledTextFieldGreen"
          label={t('reportPeriod')}
          value={period}
          onChange={(e) => dispatch(reportsActions.updatePeriod(e.target.value))}
          data={['today', 'yesterday', 'thisWeek', 'previousWeek', 'thisMonth', 'previousMonth', 'custom']}
          keyGetter={(it) => it}
          titleGetter={(it) => t(prefixString('report', it))}
          emptyValue={null}
        />
      </div>
      {period === 'custom' && (
        <div className={classes.filterItem}>
          <TextField
            className={classes.datetimeField}
            variant="filled"
            color="filledTextFieldGreen"
            label={t('reportFrom')}
            type="datetime-local"
            value={from}
            onChange={(e) => dispatch(reportsActions.updateFrom(e.target.value))}
            fullWidth
          />
        </div>
      )}
      {period === 'custom' && (
        <div className={classes.filterItem}>
          <TextField
            className={classes.datetimeField}
            variant="filled"
            color="filledTextFieldGreen"
            label={t('reportTo')}
            type="datetime-local"
            value={to}
            onChange={(e) => dispatch(reportsActions.updateTo(e.target.value))}
            fullWidth
          />
        </div>
      )}
      {children}
      <div className={classes.filterItem}>
        <Button
          fullWidth
          variant="contained"
          color="containedButtonGreen2"
          disabled={disabled}
          onClick={() => handleClick('json')}
        >
          <Typography variant="button" noWrap>{t('reportShow')}</Typography>
        </Button>
      </div>
    </div>
  );
};

export default ReplayFilter;
