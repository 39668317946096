import React, { useState, useCallback } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody,
  Button, Typography, debounce,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffectAsync } from '../../reactHelper';
import { useTranslation } from '../../common/components/LocalizationProvider';
import CollectionActions from '../components/CollectionActions';
import PageLayout from '../../common/components/PageLayout';
import SettingsMenu from '../components/SettingsMenu';
import SearchHeader from '../components/SearchHeader';
import AddNewsView from '../components/AddNewsView';
import { useAdministrator } from '../../common/util/permissions';
import { truncateText } from '../../common/util/formatter';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
  filter: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    padding: theme.spacing(3, 2, 2),
    alignItems: 'center', // Add this line
  },
  filterItem: {
    minWidth: 0,
    flex: '3 4', // Adjust the width of SearchHeader
  },
  buttonItem: {
    minWidth: 0,
    flex: '1 4', // Adjust the width of Button
  },
}));

const NewsPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const admin = useAdministrator();
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [newsItems, setNewsItems] = useState([]);
  const [newsId, setNewsId] = useState(0);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      let response;
      if (searchKeyword) {
        response = await fetch(`/api/management?q=${searchKeyword}`);
      } else {
        response = await fetch('/api/management/news-summary');
      }
      if (response.ok) {
        const getNews = await response.json();
        setNewsItems(getNews.data);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  const handleEdit = (itemId) => {
    setView(true);
    setEdit(true);
    setNewsId(itemId);
  };

  const handleSearch = useCallback(debounce(() => {
    setTimestamp(Date.now());
  }, 500), []);

  const handleSearchKeywordOnChange = (value) => {
    setSearchKeyword(value);
    handleSearch(value);
  };

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'settingsNews']}>
      <div className={classes.filter}>
        <div className={classes.filterItem}>
          <SearchHeader
            keyword={searchKeyword}
            onChange={handleSearchKeywordOnChange}
            alwaysShow
          />
        </div>
        <div className={classes.buttonItem}>
          <Button
            fullWidth
            variant="contained"
            color="containedButtonGreen2"
            // disabled={disabled}
            onClick={() => setView(true)}
          >
            <Typography variant="button" noWrap>{t('newsAdd')}</Typography>
          </Button>
        </div>
        <AddNewsView
          open={view}
          id={newsId}
          isEdit={edit}
          setTimestamp={setTimestamp}
          onClose={() => {
            setView(false);
            setEdit(false);
          }}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('newsId')}</TableCell>
            <TableCell>{t('newsHead')}</TableCell>
            {/* <TableCell>{t('newsContent')}</TableCell> */}
            <TableCell>{t('newsValidatePeriod')}</TableCell>
            <TableCell>{t('newsLastUpdated')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && newsItems.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.id}</TableCell>
              <TableCell>{truncateText(item.title, 25)}</TableCell>
              {/* <TableCell>{truncateText(item.content, 15)}</TableCell> */}
              <TableCell>
                {`${new Date(item.startTime).toLocaleDateString()} - ${new Date(item.endTime).toLocaleDateString()}`}
              </TableCell>
              <TableCell>{new Date(item.lastUpdateTime).toLocaleString()}</TableCell>
              <TableCell className={classes.columnAction} padding="none">
                <CollectionActions
                  readonly={!admin}
                  itemId={item.id}
                  itemName={item.name}
                  itemTypeName={t('settingsNews')}
                  editPath="/settings/user"
                  endpoint="/management/news"
                  setTimestamp={setTimestamp}
                  removePath={null}
                  removeDialogContent={t('sharedDeleteItemConfirm', { itemName: `${item.title}` })}
                  onEdit={handleEdit}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </PageLayout>
  );
};

export default NewsPage;
