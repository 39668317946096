import React from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, Button, Typography } from '@mui/material';
import { Apple } from '@mui/icons-material';
import GoogleIcon from '../resources/images/google-icon.png';
import FacebookIcon from '../resources/images/facebook-icon.png';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.375rem',
    color: (props) => (props.pageStyle === 'login' ? theme.palette.colors.white : theme.palette.text.hint),
  },
  dividerLine: {
    width: '9.375rem',
    border: 'none',
    height: '1px',
    backgroundColor: (props) => (props.pageStyle === 'login' ? theme.palette.colors.white : theme.palette.text.hint),
  },
  titleText: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '2rem',
    textAlign: 'center',
    color: (props) => (props.pageStyle === 'login' ? theme.palette.colors.white : theme.palette.text.primary),
  },
  thridPartyLoginBtn: {
    width: 0,
    height: 0,
    minWidth: 0,
    padding: '1.5rem',
    borderRadius: '1.5rem',
    backgroundColor: theme.palette.colors.white,
    '&:hover': {
      backgroundColor: theme.palette.containedButtonGrey.dark,
    },
  },
  horizontalBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '3rem',
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem',
    marginLeft: '12px',
  },
}));

const ThirdPartyAuthContainer = ({
  titleText,
  googleLoginEnabled,
  facebookLoginEnabled,
  appleLoginEnabled,
  handle3rdPartyLogin,
  pageStyle = '',
}) => {
  const classes = useStyles({ pageStyle });
  const t = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.dividerContainer}>
        <hr className={classes.dividerLine} />
        <Typography className={classes.dividerOr}>
          {t('or')}
        </Typography>
        <hr className={classes.dividerLine} />
      </div>
      <Typography className={classes.titleText}>
        {titleText}
      </Typography>
      <div className={classes.horizontalBtnContainer}>
        {googleLoginEnabled && (
          <Button
            className={classes.thridPartyLoginBtn}
            onClick={() => handle3rdPartyLogin('google')}
            startIcon={<Avatar src={GoogleIcon} className={classes.icon} />}
          />
        )}
        {facebookLoginEnabled && (
          <Button
            className={classes.thridPartyLoginBtn}
            onClick={() => handle3rdPartyLogin('facebook')}
            startIcon={<Avatar src={FacebookIcon} className={classes.icon} />}
          />
        )}
        {appleLoginEnabled && (
          <Button
            className={classes.thridPartyLoginBtn}
            onClick={() => handle3rdPartyLogin('apple')}
            startIcon={<Apple className={classes.icon} sx={{ color: '#000', transform: 'scale(1.2)' }} />}
          />
        )}
      </div>
    </div>
  );
};

export default ThirdPartyAuthContainer;
