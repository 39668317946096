import React from 'react';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Loading from './components/Loading';
import MainLayout from '../common/components/MainLayout';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    borderRadius: 0,
    margin: 'auto 0',
    height: '100%',
    backgroundColor: 'transparent',
  },
  form: {
    maxWidth: theme.spacing(52),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const LandingLoginLayout = ({ header, children, loadingMessage, onSubmit, style }) => {
  const classes = useStyles();

  return (
    <MainLayout header={header} style={style}>
      {!(loadingMessage === undefined || loadingMessage === null) ? (
        <Loading message={loadingMessage} />
      ) : (
        <Paper className={classes.paper}>
          <form className={classes.form} onSubmit={onSubmit}>
            {children}
          </form>
        </Paper>
      )}
    </MainLayout>
  );
};

export default LandingLoginLayout;
