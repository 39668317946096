import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Button, IconButton, InputAdornment, TextField, Typography,
} from '@mui/material';
import { ArrowBack, Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import CardSection from '../../../common/components/CardSection';

const PasswordVerfiySection = ({
  handlePasswordVerfiy, password, passwordError, onPasswordChange,
}) => {
  const navigate = useNavigate();
  const t = useTranslation();

  const currentUser = useSelector((state) => state.session.user);
  const [showPassword, setShowPassword] = useState(false);

  const handleToggleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForgotPassword = async () => {
    navigate('/reset-password');
  };

  const handleSpecialKey = (event) => {
    if (event.keyCode === 13 && password) {
      handlePasswordVerfiy(event);
    }
  };

  return (
    <CardSection
      navIcon={<ArrowBack />}
      header={(
        <Typography sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}>
          {t('twoFactorAuthentication')}
        </Typography>
      )}
      description={currentUser.isPhoneMultiFactorEnabled ? t('twoFactorAuthenticationDescriptionDisable') : t('twoFactorAuthenticationDescriptionEnable')}
      button={(
        <Button
          fullWidth
          variant="contained"
          color="containedButtonGreen2"
          disabled={!password}
          onClick={handlePasswordVerfiy}
        >
          {currentUser.isPhoneMultiFactorEnabled ? t('twoFactorAuthenticationButtonDisableIt') : t('twoFactorAuthenticationButtonNext')}
        </Button>
      )}
    >
      <TextField
        fullWidth
        required
        label={t('userPassword')}
        variant="filled"
        color="filledTextFieldGreen"
        autoFocus
        value={password}
        type={showPassword ? 'text' : 'password'}
        onChange={onPasswordChange}
        onKeyUp={handleSpecialKey}
        error={passwordError}
        helperText={passwordError ? t('wrong password') : ' '}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleToggleShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                tabIndex={-1}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div>
        <Button
          variant="text"
          onClick={handleForgotPassword}
        >
          {t('loginForgotPassword')}
        </Button>
      </div>
    </CardSection>
  );
};

export default PasswordVerfiySection;
