import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectAsync } from '../../reactHelper';
import { useTranslation } from '../../common/components/LocalizationProvider';
import useReportStyles from '../common/useReportStyles';
import { devicesActions, groupsActions } from '../../store';
import SelectField from '../../common/components/SelectField';

const DeviceFilter = ({
  children, allDevices, devicesWithGroup, groupDevices, handleSubmit, multiDevice, multiGroup, includeGroups,
}) => {
  const classes = useReportStyles();
  const dispatch = useDispatch();
  const t = useTranslation();
  const navigate = useNavigate();

  const groups = useSelector((state) => state.groups.items);

  const deviceId = useSelector((state) => state.devices.selectedId);
  const deviceIds = useSelector((state) => state.devices.selectedIds);
  const groupId = useSelector((state) => state.groups.groupId);
  const groupIds = useSelector((state) => state.groups.groupIds);

  const [devices, setDevices] = useState([]);
  const [allGroupIds, setAllGroupIds] = useState([]);
  const [deviceDisplayAll, setDeviceDisplayAll] = useState(false);
  const [groupDisplayAll, setGroupDisplayAll] = useState(false);

  const disabled = (!deviceId && !deviceIds.length) && (!groupId);

  useEffect(() => {
    // initial select menu state
    devicesActions.selectId([]);
    dispatch(devicesActions.selectIds([]));
    dispatch(groupsActions.selectGroupId([]));
  }, []);

  useEffectAsync(async () => {
    const getRegistedDevices = await fetch('/api/devices');
    const getGroupDevices = await fetch('/api/groups/all-group-devices');
    if (getRegistedDevices.ok && getGroupDevices.ok) {
      const devices = await getRegistedDevices.json();
      const groupDevices = await getGroupDevices.json();
      const uniqueDevices = [...new Map(groupDevices.map((device) => [device.id, device])).values()];
      setDevices([...devices, ...uniqueDevices]);
    } else {
      throw Error(await getRegistedDevices.text());
    }
  }, []);

  useEffect(() => {
    if (Object.keys(groups).length > 0) {
      const ids = [...Object.values(groups).map((group) => group.id)];
      setAllGroupIds(ids);
    }
  }, [groups]);

  const handleClick = () => {
    handleSubmit({
      deviceIds,
      groupIds,
    });
  };

  const handleDevices = (e) => {
    if (e.target.value.length === 1 && e.target.value.includes('all')) {
      const ids = [...Object.values(devices).map((device) => device.id)];
      dispatch(devicesActions.selectIds(ids));
    } else if (e.target.value.length !== 1 && e.target.value.includes('all')) {
      dispatch(devicesActions.selectIds([]));
    } else {
      dispatch(multiDevice ? devicesActions.selectIds(e.target.value) : devicesActions.selectId(e.target.value));
      setDeviceDisplayAll(true);
      if (e.target.value.length === 0) setDeviceDisplayAll(false);
    }
  };

  const handleGroups = (e) => {
    // initialize device select menu status
    dispatch(devicesActions.selectIds([]));
    setDeviceDisplayAll(false);
    if (e.target.value.length === 1 && e.target.value.includes('all')) {
      // [select all] group item
      dispatch(groupsActions.selectGroupIds(allGroupIds));
      const filteredDevices = devicesWithGroup.filter((device) => allGroupIds.includes(device.groupId));
      const filteredGroupDevices = groupDevices.filter((device) => allGroupIds.includes(device.groupId));
      const uniqueDevices = [...new Map(filteredDevices.map((device) => [device.id, device])).values()];
      const uniqueGroupDevices = [...new Map(filteredGroupDevices.map((device) => [device.id, device])).values()];
      setDevices([...uniqueDevices, ...uniqueGroupDevices]);
    } else if (e.target.value.length !== 1 && e.target.value.includes('all')) {
      // [cancel all] group item
      dispatch(groupsActions.selectGroupIds([]));
      setDevices(allDevices);
    } else {
      // [select one or more] group items
      dispatch(groupsActions.selectGroupIds(e.target.value));
      const filteredDevices = devicesWithGroup.filter((device) => e.target.value.includes(device.groupId));
      const filteredGroupDevices = groupDevices.filter((device) => e.target.value.includes(device.groupId));
      const uniqueDevices = [...new Map(filteredDevices.map((device) => [device.id, device])).values()];
      const uniqueGroupDevices = [...new Map(filteredGroupDevices.map((device) => [device.id, device])).values()];
      setDevices([...uniqueDevices, ...uniqueGroupDevices]);
      setGroupDisplayAll(true);
      if (e.target.value.length === 0) setGroupDisplayAll(false);
    }
  };

  return (
    <div className={classes.filter}>
      <div className={classes.filterItem}>
        <SelectField
          fullWidth
          color="filledTextFieldBlue"
          label={t(multiDevice ? 'deviceTitle' : 'reportDevice')}
          value={multiDevice ? deviceIds : deviceId || ''}
          onChange={handleDevices}
          multiple={multiDevice}
          data={[
            {
              id: 'all',
              name: deviceDisplayAll ? t('deviceCancelAll') : t('deviceSelectAll'),
              customProps: { onClick: () => { setDeviceDisplayAll(!deviceDisplayAll); } },
            },
            ...(Object.values(devices).sort((a, b) => (a.name || '').localeCompare(b.name || ''))),
          ]}
          keyGetter={(device) => `${device.id}-${device.groupName}`}
          valueGetter={(device) => device.id}
          titleGetter={(device) => device.name}
        />
      </div>
      {includeGroups && (
        <div className={classes.filterItem}>
          <SelectField
            fullWidth
            color="filledTextFieldBlue"
            label={t('settingsGroups')}
            value={multiGroup ? groupIds : groupId || ''}
            onChange={handleGroups}
            multiple={multiGroup}
            data={[
              {
                id: 'all',
                name: groupDisplayAll ? t('groupCancelAll') : t('groupSelectAll'),
                customProps: { onClick: () => { setGroupDisplayAll(!groupDisplayAll); } },
              },
              ...(Object.values(groups).sort((a, b) => (a.name || '').localeCompare(b.name || ''))),
            ]}
            keyGetter={(group) => group.id}
            titleGetter={(group) => group.name}
          />
        </div>
      )}
      {children}
      <div className={classes.filterItem}>
        <Button
          fullWidth
          variant="contained"
          color="containedButtonBlue"
          disabled={disabled}
          onClick={() => handleClick()}
        >
          <Typography variant="button" noWrap>{t('deviceShow')}</Typography>
        </Button>
      </div>
      <div className={classes.filterItem}>
        <Button
          fullWidth
          variant="contained"
          color="containedButtonBlue"
          onClick={() => navigate('/accounts/add-device')}
        >
          <Typography variant="button" noWrap>{t('addDevice')}</Typography>
        </Button>
      </div>
    </div>
  );
};

export default DeviceFilter;
