import { createTheme } from '@mui/material/styles';
import {
  amber, grey, green, red, common,
} from '@mui/material/colors';

const theme = createTheme();
const createColor = (paletteColor) => theme.palette.augmentColor({
  color: paletteColor,
});

// MUI default colors: https://mui.com/material-ui/customization/palette/#default-colors
// https://mui.com/material-ui/customization/default-theme/
// https://github.com/mui/material-ui/blob/master/packages/mui-material/src/styles/createPalette.js
export default (server) => {
  const colors = {
    white: common.white,
    background: '#fff',
    primary: server?.attributes?.colorPrimary || '#8bba29', // '#2ecd77', // ATrack green
    secondary: server?.attributes?.colorSecondary || '#8bba29', // '#1eee7e', // ATrack light green
    positive: green[500],
    medium: amber[700],
    negative: red[500],
    neutral: grey[500],
    geometry: '#3bb2d0',
    // the above are original colors of Traccar
    // ATrack custom colors
    text: {
      primary: '#1d1d1d',
      secondary: '#757575',
      disable: '#cac4d0',
      hint: '#a1a1a1',
      nav: '#49454f',
      link: '#3366bb',
    },
    divider: '#cac4d0',
    header: '#88b928', // main layout header
    tableHeader: '#c7e0a4', // reports table header
    tableCheckboxRed: '#ea3b4a',
    switch: {
      active: '#2196f3',
      activeDisabled: '#558fbe',
    },
    dialog: {
      main: {
        background: '#fff',
        title: '#1d1d1d',
        content: '#1d1d1d',
        button: '#2ecd77',
      },
      dark: {
        background: '#383838',
        title: '#fff',
        content: '#cecece',
        button: '#2ecd77',
      },
    },
    mapSidebar: {
      background: '#e5e5e5',
      nav: '#0e3463',
      button: {
        // default: '', no color
        hover: '#ffffff33',
        active: '#b5dd5c',
        activeHover: 'b8f045',
      },
      text: {
        default: '#fff',
        hover: '#fff',
        active: '#1d1d1d',
        activeHover: '#1d1d1d',
      },
    },
    pageSidebar: {
      background: '#0e3463',
      text: {
        default: '#fff',
        subheader: '#8bba29',
      },
      divider: '#487ab7',
    },
    // sub-pages header
    pageHeader: {
      background: '#abbbcd',
    },
    // sidebar menu, event-notification items, map sidebar items: {
    menu: {
      active: {
        background: '#487ab7',
        hover: '#5fa1f2',
      },
      inactive: {
        hover: '#487ab750',
      },
    },
  };

  return {
    mode: 'light',
    colors,
    background: {
      default: colors.background,
      paper: colors.background,
    },
    primary: {
      main: colors.primary,
      contrastText: colors.text.primary,
    },
    secondary: {
      main: colors.secondary,
      contrastText: colors.text.primary,
    },
    text: colors.text,
    divider: colors.divider,
    mapSidebar: colors.mapSidebar,
    pageSidebar: colors.pageSidebar,
    pageHeader: colors.pageHeader,
    containedButtonGreen: createColor({
      main: colors.secondary,
      dark: '#9dd626', // hover, focus
      contrastText: colors.white,
    }),
    containedButtonGreen2: createColor({
      main: '#cef09a',
      dark: '#d4f3a9',
      contrastText: colors.text.primary,
    }),
    containedButtonGrey: createColor({
      main: '#f7f7f7',
      dark: '#f1f1f1',
      contrastText: colors.text.primary,
    }),
    containedButtonBlue: createColor({
      main: '#e7eef5',
      dark: '#f1f8ff',
      contrastText: colors.text.primary,
    }),
    outlinedButtonGreen: createColor({
      main: colors.text.primary,
      hover: '#e0fff2',
      disable: '#cac4d0',
    }),
    chipBlue: createColor({
      main: '#2196f3',
      contrastText: colors.white,
    }),
    chipGreen: createColor({
      main: colors.primary,
      contrastText: colors.white,
    }),
    chipGrey: createColor({
      main: '#f6f6f6',
      contrastText: colors.text.primary,
    }),
    chipRed: createColor({
      main: '#b3261e',
      contrastText: colors.white,
    }),
    filledTextFieldWhite: createColor({
      main: '#fff',
      contrastText: '#fff',
    }),
    filledTextFieldGreen: createColor({
      main: colors.primary,
      contrastText: colors.primary,
    }),
    filledTextFieldLogin: createColor({
      main: colors.primary,
      contrastText: colors.primary,
    }),
    filledTextFieldBlue: createColor({
      main: '#6d88a8',
      contrastText: '#6d88a8',
    }),
  };
};
