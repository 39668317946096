import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  IconButton, Typography, Box,
  TextField, InputAdornment,
  FormGroup, Checkbox, ListItemText, ListItemIcon, Button,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import makeStyles from '@mui/styles/makeStyles';
import AccountMenu from './components/AccountMenu';
import PageLayout from '../common/components/PageLayout';
import CardLayout from '../common/components/CardLayout';
import CardSection from '../common/components/CardSection';
import { useTranslation } from '../common/components/LocalizationProvider';
import DeleteView from './components/DeleteView';
import { useEffectAsync } from '../reactHelper';
import { filterFullNameByKeyword } from './components/SearchHeader';
import { truncateText } from '../common/util/formatter';

const useStyles = makeStyles(() => ({
  switchContainer: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const GroupsMemberManagePage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [timestamp, setTimestamp] = useState(Date.now());
  const [group, setGroup] = useState({});
  const [members, setMembers] = useState([]);
  const [memberId, setMemberId] = useState(0);
  const [memberName, setMemberName] = useState('');
  const [deleteMemberView, setDeleteMemberView] = useState(false);
  const [keyword, setKeyword] = useState('');

  useEffectAsync(async () => {
    const response = await fetch(`/api/groups/${id}`);
    if (response.ok) {
      const group = await response.json();
      setGroup(group);
    } else {
      throw Error(await response.text());
    }
  }, [id]);

  useEffectAsync(async () => {
    const getMembers = await fetch(`/api/groups-members/members?groupId=${id}`);
    try {
      if (getMembers.ok) {
        const thisMembers = await getMembers.json();
        setMembers(thisMembers);
      } else {
        setMembers([]);
        throw Error(await getMembers.text());
      }
    } catch (error) {
      setMembers([]);
      throw Error(error);
    }
  }, [id, timestamp]);

  return (
    <PageLayout menu={<AccountMenu />} breadcrumbs={['settingsUser', 'deviceTitle']}>
      <CardLayout disableDivider>
        <CardSection
          navIcon={<ArrowBackIcon />}
          header={t('groupMemberManageShow')}
        >
          <Box sx={{ marginBottom: '1.12rem' }}>
            <Typography gutterBottom variant="h5" component="div">
              {group.name}
            </Typography>
          </Box>
          <TextField
            variant="filled"
            color="filledTextFieldGreen"
            label={t('searchByName')}
            helperText=" "
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="body2" sx={{ marginTop: '0.87rem', marginBottom: '1.19rem' }}>
            {t('groupMemberMessage')}
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="containedButtonGreen2"
            onClick={() => { navigate(`/accounts/member-invite/${id}`); }}
          >
            {t('groupInvited')}
          </Button>
          <FormGroup>
            <div className={classes.switchContainer}>
              <ListItemText primary={t('groupAllMember')} />
            </div>
            { members.filter(filterFullNameByKeyword(keyword)).map((member) => (
              <div className={classes.switchContainer} key={member.userId}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={truncateText(member.fullName, 25)} />
                { member.role === 'member' && (
                <Checkbox
                  onClick={() => {
                    setMemberId(member.userId);
                    setMemberName(member.fullName);
                    setDeleteMemberView(true);
                  }}
                  color="error"
                  indeterminate
                />
                )}
              </div>
            )) }
          </FormGroup>
          <Button
            fullWidth
            variant="contained"
            color="containedButtonGreen2"
            sx={{ marginTop: '1.13rem' }}
            onClick={() => { navigate(`/accounts/admin-transfer/${id}`); }}
          >
            {t('groupAdminTransfer')}
          </Button>
        </CardSection>
      </CardLayout>
      <DeleteView open={deleteMemberView} memberId={memberId} onClose={() => setDeleteMemberView(false)} setTimestamp={setTimestamp} title={t('groupMemberDelete')} content={t('groupMemberDeleteMessage', { memberName })} />
    </PageLayout>
  );
};

export default GroupsMemberManagePage;
