import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button, Typography,
} from '@mui/material';
import { useEffectAsync } from '../../reactHelper';
import { useTranslation } from '../../common/components/LocalizationProvider';
import useReportStyles from '../common/useReportStyles';
import SelectField from '../../common/components/SelectField';

const DriverFilter = ({
  children, handleSubmit,
}) => {
  const classes = useReportStyles();
  const t = useTranslation();
  const navigate = useNavigate();
  const [devices, setDevices] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(0);
  const [selectedDevice, setSelectedDevice] = useState(0);
  const [disableDriver, setDisableDriver] = useState(false);
  const [disableDevice, setDisableDevice] = useState(false);

  useEffect(() => {
    setSelectedDriver('all');
    setSelectedDevice('none');
  }, []);
  useEffectAsync(async () => {
    const response = await fetch('/api/devices');
    if (response.ok) {
      const getDevices = await response.json();
      setDevices(getDevices);
    } else {
      throw Error(await response.text());
    }
  }, []);

  useEffectAsync(async () => {
    const response = await fetch('/api/drivers');
    if (response.ok) {
      const getDrivers = await response.json();
      setDrivers(getDrivers);
    } else {
      throw Error(await response.text());
    }
  }, []);

  const handleClick = () => {
    handleSubmit({
      selectedDriver,
      selectedDevice,
    });
  };

  return (
    <div className={classes.filter}>
      <div className={classes.filterItem}>
        <SelectField
          fullWidth
          color="filledTextFieldBlue"
          label={t('driverTitle')}
          value={selectedDriver || ''}
          disabled={disableDriver}
          onChange={(e) => {
            setSelectedDriver(e.target.value);
            if (e.target.value !== 'all') {
              setDisableDevice(true);
            }
          }}
          data={[
            {
              id: 'all',
              name: t('sharedSelectAll'),
              customProps: { onClick: () => { setDisableDevice(false); } },
            },
            ...drivers,
          ]}
          emptyValue={null}
        />
      </div>
      <div className={classes.filterItem}>
        <SelectField
          fullWidth
          color="filledTextFieldBlue"
          label={t('deviceTitle')}
          value={selectedDevice || ''}
          disabled={disableDevice}
          onChange={(e) => {
            setSelectedDevice(e.target.value);
            if (e.target.value !== 'none') {
              setDisableDriver(true);
            }
          }}
          data={[
            {
              id: 'none',
              name: t('sharedSelectAll'),
              customProps: { onClick: () => { setDisableDriver(false); } },
            },
            ...devices,
          ]}
          emptyValue={null}
        />
      </div>
      {children}
      <div className={classes.filterItem}>
        <Button
          fullWidth
          variant="contained"
          color="containedButtonBlue"
          // disabled={disabled}
          onClick={() => handleClick()}
        >
          <Typography variant="button" noWrap>{t('driverShow')}</Typography>
        </Button>
      </div>
      <div className={classes.filterItem}>
        <Button
          fullWidth
          variant="contained"
          color="containedButtonBlue"
          onClick={() => navigate('/accounts/add-driver')}
        >
          <Typography variant="button" noWrap>{t('addNewDriver')}</Typography>
        </Button>
      </div>
    </div>
  );
};

export default DriverFilter;
