import React from 'react';
import { useTranslation } from '../../common/components/LocalizationProvider';
import useReportStyles from '../common/useReportStyles';
import SelectField from '../../common/components/SelectField';

const ColumnSelect = ({
  columns, setColumns, columnsArray, columnsObject, rawValues, disabled,
  inputFieldColor = 'filledTextFieldBlue',
}) => {
  const classes = useReportStyles();
  const t = useTranslation();

  return (
    <div className={classes.filterItem}>
      <SelectField
        fullWidth
        color={inputFieldColor}
        label={t('sharedColumns')}
        value={columns}
        onChange={(e) => setColumns(e.target.value)}
        multiple
        disabled={disabled}
        data={columnsArray || Object.keys(columnsObject)}
        keyGetter={(it) => (columnsArray ? it[0] : it)}
        titleGetter={(it) => (columnsArray ? (rawValues ? it[1] : t(it[1])) : columnsObject[it].name)}
      />
    </div>
  );
};

export default ColumnSelect;
