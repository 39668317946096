import React, { useState } from 'react';
import {
  Button, TextField, Typography,
} from '@mui/material';
import { useTranslation } from '../../common/components/LocalizationProvider';
import SplitButton from '../../common/components/SplitButton';
import periodConverter from '../../common/util/periodConverter';
import useReportStyles from '../../other/common/useReportStyles';
import SelectField from '../../common/components/SelectField';
import { prefixString } from '../../common/util/stringUtils';

const ReportFilter = ({
  children, handleSubmit, showOnly = true,
}) => {
  const classes = useReportStyles();
  const t = useTranslation();

  const [period, setPeriod] = useState('today');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [button, setButton] = useState('json');

  const handleClick = (type) => {
    const seletecdPeriod = periodConverter(period, from, to);

    handleSubmit({
      from: seletecdPeriod.selectedFrom.toISOString(),
      to: seletecdPeriod.selectedTo.toISOString(),
      type,
    });
  };

  return (
    <div className={classes.filter}>
      <div className={classes.filterItem}>
        <SelectField
          fullWidth
          label={t('reportPeriod')}
          color="filledTextFieldGreen"
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
          data={['today', 'yesterday', 'thisWeek', 'previousWeek', 'thisMonth', 'previousMonth', 'custom']}
          keyGetter={(it) => it}
          titleGetter={(it) => t(prefixString('report', it))}
          emptyValue={null}
        />
      </div>
      {period === 'custom' && (
        <div className={classes.filterItem}>
          <TextField
            className={classes.datetimeField}
            variant="filled"
            color="filledTextFieldGreen"
            label={t('reportFrom')}
            type="datetime-local"
            value={from}
            onChange={(e) => setFrom(e.target.value)}
            fullWidth
          />
        </div>
      )}
      {period === 'custom' && (
        <div className={classes.filterItem}>
          <TextField
            className={classes.datetimeField}
            variant="filled"
            color="filledTextFieldGreen"
            label={t('reportTo')}
            type="datetime-local"
            value={to}
            onChange={(e) => setTo(e.target.value)}
            fullWidth
          />
        </div>
      )}
      {children}
      <div className={classes.filterItem}>
        {showOnly ? (
          <Button
            fullWidth
            variant="contained"
            color="containedButtonGreen2"
            onClick={() => handleClick('json')}
          >
            <Typography variant="button" noWrap>{t('reportShow')}</Typography>
          </Button>
        ) : (
          <SplitButton
            fullWidth
            variant="contained"
            color="containedButtonGreen2"
            onClick={handleClick}
            selected={button}
            setSelected={(value) => setButton(value)}
            options={{
              json: t('reportShow'),
              export: t('reportExport'),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ReportFilter;
