import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import SelectField from '../../../common/components/SelectField';

const SupportTicketDeviceSelect = ({ onChange, value }) => {
  const t = useTranslation();
  const devices = useSelector((state) => ({ ...state.devices.items }));

  return (
    <SelectField
      color="filledTextFieldGreen"
      label={t('deviceTitle')}
      value={value || ''}
      onChange={onChange}
      helperText=" "
      data={Object.values(devices)}
      titleGetter={(it) => it.name}
      emptyValue={null}
    />
  );
};

export default SupportTicketDeviceSelect;
