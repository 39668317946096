import React, { useState } from 'react';
import {
  Button, TextField, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import useReportStyles from '../common/useReportStyles';
import { devicesActions, reportsActions } from '../../store';
import SplitButton from '../../common/components/SplitButton';
import SelectField from '../../common/components/SelectField';
import { useRestriction } from '../../common/util/permissions';
import periodConverter from '../../common/util/periodConverter';
import { prefixString } from '../../common/util/stringUtils';

const ReportFilter = ({
  children, handleSubmit, handleSchedule, showOnly = true, ignoreDevice, multiDevice, includeGroups, includeUsers,
  inputFieldColor = 'filledTextFieldBlue', selectedColumns,
}) => {
  const classes = useReportStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const readonly = useRestriction('readonly');

  const devices = useSelector((state) => ({ ...state.devices.items, ...state.devices.groupItems }));
  const groups = useSelector((state) => state.groups.items);
  const users = useSelector((state) => state.users.items);

  const deviceId = useSelector((state) => state.devices.selectedId);
  const deviceIds = useSelector((state) => state.devices.selectedIds);
  const groupIds = useSelector((state) => state.reports.groupIds);
  const period = useSelector((state) => state.reports.period);
  const from = useSelector((state) => state.reports.from);
  const to = useSelector((state) => state.reports.to);
  const [button, setButton] = useState('json');

  const [description, setDescription] = useState();
  const [calendarId, setCalendarId] = useState();
  const [userId, setUserId] = useState();

  const scheduleDisabled = button === 'schedule' && (!description || !calendarId);
  const disabled = (!ignoreDevice && !deviceId && !deviceIds.length && !groupIds.length) || scheduleDisabled || (includeUsers && !userId) || (selectedColumns.length < 1);

  const handleClick = (type) => {
    if (type === 'schedule') {
      handleSchedule(deviceIds, groupIds, {
        description,
        calendarId,
        attributes: {},
      });
    } else {
      const seletecdPeriod = periodConverter(period, from, to);

      handleSubmit({
        deviceId,
        deviceIds,
        groupIds,
        from: seletecdPeriod.selectedFrom.toISOString(),
        to: seletecdPeriod.selectedTo.toISOString(),
        calendarId,
        type,
        userId,
      });
    }
  };

  return (
    <div className={classes.filter}>
      {!ignoreDevice && (
        <div className={classes.filterItem}>
          <SelectField
            fullWidth
            color={inputFieldColor}
            label={t(multiDevice ? 'deviceTitle' : 'reportDevice')}
            value={multiDevice ? deviceIds : deviceId || ''}
            onChange={(e) => dispatch(multiDevice ? devicesActions.selectIds(e.target.value) : devicesActions.selectId(e.target.value))}
            multiple={multiDevice}
            data={Object.values(devices).sort((a, b) => a.name.localeCompare(b.name))}
            keyGetter={(it) => it.id}
            titleGetter={(it) => it.name}
            emptyValue={null}
          />
        </div>
      )}
      {includeGroups && (
        <div className={classes.filterItem}>
          <SelectField
            fullWidth
            color={inputFieldColor}
            label={t('settingsGroups')}
            value={groupIds}
            onChange={(e) => dispatch(reportsActions.updateGroupIds(e.target.value))}
            multiple
            data={Object.values(groups).sort((a, b) => a.name.localeCompare(b.name))}
            keyGetter={(it) => it.id}
            titleGetter={(it) => it.name}
            emptyValue=""
          />
        </div>
      )}
      {includeUsers && (
        <div className={classes.filterItem}>
          <SelectField
            fullWidth
            color={inputFieldColor}
            label={t('userEmail')}
            value={userId}
            onChange={(event) => setUserId(Number(event.target.value))}
            data={Object.values(users).sort((a, b) => a.email.localeCompare(b.email))}
            keyGetter={(it) => it.id}
            titleGetter={(it) => it.email}
            emptyValue={null}
          />
        </div>
      )}
      {button !== 'schedule' ? (
        <>
          <div className={classes.filterItem}>
            <SelectField
              fullWidth
              color={inputFieldColor}
              label={t('reportPeriod')}
              value={period}
              onChange={(e) => dispatch(reportsActions.updatePeriod(e.target.value))}
              data={['today', 'yesterday', 'thisWeek', 'previousWeek', 'thisMonth', 'previousMonth', 'custom']}
              keyGetter={(it) => it}
              titleGetter={(it) => t(prefixString('report', it))}
              emptyValue={null}
            />
          </div>
          {period === 'custom' && (
            <div className={classes.filterItem}>
              <TextField
                className={classes.datetimeField}
                variant="filled"
                color={inputFieldColor}
                label={t('reportFrom')}
                type="datetime-local"
                value={from}
                onChange={(e) => dispatch(reportsActions.updateFrom(e.target.value))}
                fullWidth
              />
            </div>
          )}
          {period === 'custom' && (
            <div className={classes.filterItem}>
              <TextField
                className={classes.datetimeField}
                variant="filled"
                color={inputFieldColor}
                label={t('reportTo')}
                type="datetime-local"
                value={to}
                onChange={(e) => dispatch(reportsActions.updateTo(e.target.value))}
                fullWidth
              />
            </div>
          )}
        </>
      ) : (
        <>
          <div className={classes.filterItem}>
            <TextField
              value={description || ''}
              onChange={(event) => setDescription(event.target.value)}
              label={t('sharedDescription')}
              fullWidth
            />
          </div>
          <div className={classes.filterItem}>
            <SelectField
              value={calendarId || 0}
              onChange={(event) => setCalendarId(Number(event.target.value))}
              endpoint="/api/calendars"
              label={t('sharedCalendar')}
              fullWidth
            />
          </div>
        </>
      )}
      {children}
      <div className={classes.filterItem}>
        {showOnly ? (
          <Button
            fullWidth
            variant="contained"
            color="containedButtonBlue"
            disabled={disabled}
            onClick={() => handleClick('json')}
          >
            <Typography variant="button" noWrap>{t('reportShow')}</Typography>
          </Button>
        ) : (
          <SplitButton
            fullWidth
            variant="contained"
            color="containedButtonBlue"
            disabled={disabled}
            onClick={handleClick}
            selected={button}
            setSelected={(value) => setButton(value)}
            options={readonly || !handleSchedule ? {
              json: t('reportShow'),
              export: t('reportExport'),
              mail: t('reportEmail'),
            } : {
              json: t('reportShow'),
              export: t('reportExport'),
              mail: t('reportEmail'),
              schedule: t('reportSchedule'),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ReportFilter;
