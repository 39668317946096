import React, { useState } from 'react';
import {
  AppBar,
  Breadcrumbs,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  List,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from './LocalizationProvider';
import VersionInfo from './VersionInfo';
import AtrackLogoWhiteImage from '../../resources/images/ATrackLogo_White.png';

const useStyles = makeStyles((theme) => ({
  desktopRoot: {
    height: '100%',
    display: 'flex',
  },
  mobileRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  desktopDrawer: {
    width: theme.dimensions.drawerWidthDesktop,
    padding: '0.75rem',
    borderRightWidth: 1.5,
    backgroundColor: theme.palette.pageSidebar.background,
    color: theme.palette.pageSidebar.text.default,
  },
  mobileDrawer: {
    width: theme.dimensions.drawerWidthTablet,
    padding: '0.75rem',
    backgroundColor: theme.palette.pageSidebar.background,
    color: theme.palette.pageSidebar.text.default,
  },
  mobileToolbar: {
    zIndex: 1,
    backgroundColor: theme.palette.pageSidebar.background,
  },
  content: {
    flexGrow: 1,
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  toolbar: {
    display: 'flex',
    height: '3.5rem',
    padding: '1rem 0.5rem',
    alignItems: 'center',
  },
  iconButton: {
    margin: '0.625rem',
    color: theme.palette.pageSidebar.text.default,
  },
  pageTitle: {
    fontSize: '1.375rem',
    fontWeight: 400,
    color: theme.palette.pageSidebar.text.default,
  },
  divider: {
    margin: '0.375rem 1rem',
    borderBottomWidth: 1.5,
    borderColor: theme.palette.pageSidebar.divider,
  },
  logo: {
    width: '40%',
  },
  bottomContainer: {
    marginTop: 'auto',
  },
}));

const PageTitle = ({ breadcrumbs, className }) => {
  const theme = useTheme();
  const t = useTranslation();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  if (desktop) {
    return (
      <Typography variant="h6" noWrap className={className}>{t(breadcrumbs[0])}</Typography>
    );
  }
  return (
    <Breadcrumbs>
      {breadcrumbs.slice(0, -1).map((breadcrumb) => (
        <Typography variant="h6" color="inherit" key={breadcrumb} className={className}>{t(breadcrumb)}</Typography>
      ))}
      <Typography variant="h6" color="textPrimary" className={className}>{t(breadcrumbs[breadcrumbs.length - 1])}</Typography>
    </Breadcrumbs>
  );
};

const PageLayout = ({ menu, breadcrumbs, children, loading }) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      {loading && <LinearProgress />}
      {desktop ? (
        <div className={classes.desktopRoot}>
          <Drawer
            variant="permanent"
            className={classes.desktopDrawer}
            classes={{ paper: classes.desktopDrawer }}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton className={classes.iconButton} color="inherit" edge="start" sx={{ mr: 2 }} onClick={() => navigate('/')}>
                <ArrowBackIcon />
              </IconButton>
              <PageTitle breadcrumbs={breadcrumbs} className={classes.pageTitle} />
            </Toolbar>
            <Divider className={classes.divider} />
            {menu}
            <List className={classes.bottomContainer}>
              <MenuItem>
                <img src={AtrackLogoWhiteImage} className={classes.logo} alt="ATrack Logo" />
              </MenuItem>
            </List>
          </Drawer>
          <div className={classes.content} id="page-content">
            <VersionInfo />
            {children}
          </div>
        </div>
      ) : (
        <div className={classes.mobileRoot}>
          <Drawer
            variant="temporary"
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            classes={{ paper: classes.mobileDrawer }}
          >
            {menu}
            <List className={classes.bottomContainer}>
              <MenuItem>
                <img src={AtrackLogoWhiteImage} className={classes.logo} alt="ATrack Logo" />
              </MenuItem>
            </List>
          </Drawer>
          <AppBar className={classes.mobileToolbar} position="static" color="inherit">
            <Toolbar>
              <IconButton className={classes.iconButton} color="inherit" edge="start" sx={{ mr: 2 }} onClick={() => setOpenDrawer(true)}>
                <MenuIcon />
              </IconButton>
              <PageTitle breadcrumbs={breadcrumbs} className={classes.pageTitle} />
              <VersionInfo />
            </Toolbar>
          </AppBar>
          <div className={classes.content} id="page-content">{children}</div>
        </div>
      )}
    </>
  );
};

export default PageLayout;
