import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button, TextField, Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import BikeScooterIcon from '@mui/icons-material/BikeScooter';
import PersonIcon from '@mui/icons-material/Person';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import { useTranslation } from '../../common/components/LocalizationProvider';
import useGroupStyles from '../common/useGroupStyles';
import { devicesActions, reportsActions } from '../../store';
import periodConverter from '../../common/util/periodConverter';
import SelectField from '../../common/components/SelectField';
// import { prefixString } from '../../common/util/stringUtils';

const GroupFilter = ({
  children, handleView, devices, role, ignoreDevice, multiDevice,
}) => {
  const classes = useGroupStyles();
  const dispatch = useDispatch();
  const t = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [allDevices, setAllDevices] = useState([]);
  const [controlAll, setControlAll] = useState(false);

  const deviceId = useSelector((state) => state.devices.selectedId);
  const deviceIds = useSelector((state) => state.devices.selectedIds);
  const period = useSelector((state) => state.reports.period);
  const from = useSelector((state) => state.reports.from);
  const to = useSelector((state) => state.reports.to);

  useEffect(() => {
    if (devices.length > 0) {
      const ids = devices.map((device) => device.id);
      setAllDevices(ids);
      dispatch(devicesActions.selectIds(ids)); // initial select all devices
    }
  }, [devices]);

  const handleClick = () => {
    const seletecdPeriod = periodConverter(period, from, to);

    handleView({
      deviceId,
      deviceIds,
      from: seletecdPeriod.selectedFrom.toISOString(),
      to: seletecdPeriod.selectedTo.toISOString(),
    });
  };

  const handleDevices = (e) => {
    if (e.target.value.includes('all')) {
      if (controlAll) {
        dispatch(devicesActions.selectIds(allDevices));
      } else {
        dispatch(devicesActions.selectIds([]));
      }
    } else {
      dispatch(multiDevice ? devicesActions.selectIds(e.target.value) : devicesActions.selectId(e.target.value));
    }
  };

  return (
    <>
      <div className={classes.filter}>
        {!ignoreDevice && (
          <div className={classes.filterItem}>
            <SelectField
              fullWidth
              color="filledTextFieldBlue"
              label={t(multiDevice ? 'deviceTitle' : 'reportDevice')}
              value={multiDevice ? deviceIds : deviceId || ''}
              onChange={handleDevices}
              multiple={multiDevice}
              data={[
                {
                  id: 'all',
                  name: t('deviceSelectAll'),
                  customProps: { onClick: () => { setControlAll(!controlAll); } },
                },
                ...devices,
              ]}
              keyGetter={(device) => device.id}
              titleGetter={(device) => device.name}
            />
          </div>
        )}
        {/* {includeGroups && (
          <div className={classes.filterItem}>
            <FormControl fullWidth>
              <InputLabel className={classes.selectLabel}>{t('settingsGroups')}</InputLabel>
              <Select
                variant="filled"
                label={t('settingsGroups')}
                value={groupIds}
                onChange={(e) => dispatch(reportsActions.updateGroupIds(e.target.value))}
                multiple
              >
                {Object.values(groups).sort((a, b) => a.name.localeCompare(b.name)).map((group) => (
                  <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )} */}
        {/* <div className={classes.filterItem}>
          <SelectField
            fullWidth
            color="filledTextFieldBlue"
            label={t('reportPeriod')}
            value={period}
            onChange={(e) => dispatch(reportsActions.updatePeriod(e.target.value))}
            data={['today', 'yesterday', 'thisWeek', 'previousWeek', 'thisMonth', 'previousMonth', 'custom']}
            keyGetter={(it) => it}
            titleGetter={(it) => t(prefixString('report', it))}
            emptyValue={null}
          />
        </div> */}
        {period === 'custom' && (
          <div className={classes.filterItem}>
            <TextField
              className={classes.datetimeField}
              variant="filled"
              color="filledTextFieldBlue"
              label={t('reportFrom')}
              type="datetime-local"
              value={from}
              onChange={(e) => dispatch(reportsActions.updateFrom(e.target.value))}
              fullWidth
            />
          </div>
        )}
        {period === 'custom' && (
          <div className={classes.filterItem}>
            <TextField
              className={classes.datetimeField}
              variant="filled"
              color="filledTextFieldBlue"
              label={t('reportTo')}
              type="datetime-local"
              value={to}
              onChange={(e) => dispatch(reportsActions.updateTo(e.target.value))}
              fullWidth
            />
          </div>
        )}
        {children}
      </div>
      <div className={classes.setting}>
        <div className={classes.settingItem}>
          <Button
            fullWidth
            variant="contained"
            color="containedButtonBlue"
            disabled={devices.length < 1}
            onClick={() => handleClick()}
          >
            <Typography variant="button" noWrap sx={{ textTransform: 'none' }}>{t('groupShow')}</Typography>
          </Button>
        </div>
        {role === 'admin' && (
          <>
            <div className={classes.settingItem}>
              <Button
                fullWidth
                variant="contained"
                color="containedButtonBlue"
                startIcon={<BikeScooterIcon />}
                onClick={() => navigate(`/accounts/share-devices/${id}`)}
              >
                <Typography variant="button" noWrap sx={{ textTransform: 'none' }}>{t('groupShareDeviceShow')}</Typography>
              </Button>
            </div>
            <div className={classes.settingItem}>
              <Button
                fullWidth
                variant="contained"
                color="containedButtonBlue"
                startIcon={<PersonIcon />}
                onClick={() => navigate(`/accounts/member-manage/${id}`)}
              >
                <Typography variant="button" noWrap sx={{ textTransform: 'none' }}>{t('groupMemberManageShow')}</Typography>
              </Button>
            </div>
            <div className={classes.settingItem}>
              <Button
                fullWidth
                variant="contained"
                color="containedButtonBlue"
                startIcon={<EditRoadIcon />}
                onClick={() => navigate(`/accounts/apply-geofence/${id}`)}
              >
                <Typography variant="button" noWrap sx={{ textTransform: 'none' }}>{t('groupAdminGeofenceShow')}</Typography>
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default GroupFilter;
