import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Button, IconButton, Typography, Box,
  TextField, Chip,
} from '@mui/material';
import AccountMenu from './components/AccountMenu';
import PageLayout from '../common/components/PageLayout';
import CardLayout from '../common/components/CardLayout';
import CardSection from '../common/components/CardSection';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useCatch } from '../reactHelper';

const GroupsMemberInvitePage = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [email, setEmail] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [fail, setFail] = useState(false);
  const [errMsg, setErrMsg] = useState(' ');
  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    setIsDisable((email === '' && emailList.length < 1) || fail);
  }, [email, emailList, fail]);

  const inputEmailCheck = () => {
    let isSuccess = true;
    // check 1: email is repeat or not
    const isInputRepeat = emailList.includes(email);
    if (isInputRepeat) {
      isSuccess = false;
      setFail(true);
      setErrMsg(t('groupMemberInviteRepeatInvalidMessage'));
    }

    // check 2: email is valid format or not
    const pattern = /^[\w+]+((-[\w+])|(\.[\w+]))*@([A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+)$/;
    const emailIsValid = pattern.test(email.trim());
    if (!emailIsValid) {
      isSuccess = false;
      setFail(true);
      setErrMsg(t('groupMemberInviteInvalidEmailFormatInvalidMessage'));
    }
    return isSuccess;
  };

  const handleSend = useCatch(async () => {
    let isSuccess = true;
    if (emailList.length < 1) {
      isSuccess = inputEmailCheck();
    }
    if (!fail && isSuccess) {
      const inviteEmails = emailList.length > 0 ? emailList : email;
      const response = await fetch(`/api/groups-invited/member?groupId=${id}&emails=${encodeURIComponent(inviteEmails)}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.ok) {
        setFail(false);
        navigate(-1);
      } else {
        let errMsg = '';
        if (response.status === 403) {
          errMsg = t('groupMemberInviteMyselfInvalidMessage');
        } else if (response.status === 404) {
          errMsg = t('groupMemberInviteInvalidAccountInvalidMessage');
        } else if (response.status === 406) {
          errMsg = t('groupMemberInviteHasSendedInvalidMessage');
        } else if (response.status === 409) {
          errMsg = t('groupMemberInviteExistMemberInvalidMessage');
        }
        setFail(true);
        setErrMsg(errMsg);
      }
    }
  });

  const handleInput = useCatch(async (e) => {
    setErrMsg(' ');
    setFail(false);
    if (e.target.value.includes(',')) {
      const isSuccess = inputEmailCheck();
      if (isSuccess) {
        const verify = await fetch(`/api/groups-invited/verify?groupId=${id}&email=${encodeURIComponent(email)}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        });
        if (verify.ok) {
          setEmailList([...emailList, email]);
          setEmail('');
        } else {
          let errMsg = '';
          if (verify.status === 403) {
            errMsg = t('groupMemberInviteMyselfInvalidMessage');
          } else if (verify.status === 404) {
            errMsg = t('groupMemberInviteInvalidAccountInvalidMessage');
          } else if (verify.status === 406) {
            errMsg = t('groupMemberInviteHasSendedInvalidMessage');
          } else if (verify.status === 409) {
            errMsg = t('groupMemberInviteExistMemberInvalidMessage');
          }
          setFail(true);
          setErrMsg(errMsg);
        }
      }
    } else {
      setEmail(e.target.value);
    }
  });

  const handleDelete = (e, emailToDelete) => {
    const updatedEmailList = emailList.filter((email) => email !== emailToDelete);
    setEmailList(updatedEmailList);
  };

  return (
    <PageLayout menu={<AccountMenu />} breadcrumbs={['settingsUser', 'deviceTitle']}>
      <CardLayout>
        <CardSection
          header={(
            <Box display="flex" alignItems="center">
              <IconButton color="text.primary" sx={{ mr: 2 }} onClick={() => { navigate(-1); }}>
                <ArrowBackIcon />
              </IconButton>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
              >
                {t('groupInvited')}
              </Typography>
            </Box>
          )}
          childrenSx={{ padding: 0 }}
          button={(
            <Button
              fullWidth
              variant="contained"
              color="containedButtonGreen2"
              onClick={handleSend}
              disabled={isDisable}
            >
              {t('groupSendInvitation')}
            </Button>
          )}
        >
          <CardSection>
            <Typography
              gutterBottom
              color="primary"
              sx={{ padding: '0 10px' }}
              variant="h8"
              component="div"
            >
              {t('email')}
            </Typography>
            {emailList.length > 0 && emailList.map((email) => (
              <Box key={email} sx={{ padding: '0 10px' }}>
                <Chip label={email} size="small" variant="outlined" onDelete={(e) => handleDelete(e, email)} />
              </Box>
            ))}
            <TextField
              error={fail}
              variant="filled"
              color="filledTextFieldGreen"
              id="standard-basic"
              value={email}
              helperText={errMsg}
              onChange={handleInput}
            />
            <Typography>
              {t('groupSendInviteMessage')}
            </Typography>
          </CardSection>
        </CardSection>
      </CardLayout>
    </PageLayout>
  );
};

export default GroupsMemberInvitePage;
