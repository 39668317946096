import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField,
} from '@mui/material';
import PageLayout from '../../common/components/PageLayout';
import { useTranslation } from '../../common/components/LocalizationProvider';
import SettingsMenu from '../components/SettingsMenu';
import { useEffectAsync } from '../../reactHelper';
import TableShimmer from '../../common/components/TableShimmer';
import { formatTime } from '../../common/util/formatter';
import useReportStyles from '../../other/common/useReportStyles';
import { errorsActions } from '../../store';

const ApiLogPage = () => {
  const classes = useReportStyles();
  const dispatch = useDispatch();
  const t = useTranslation();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

  useEffectAsync(async () => {
    try {
      if (loading) {
        setItems([]);
        setTotalCount(0);
        dispatch(errorsActions.clear());
        const response = await fetch(`/api/management/api-logs?date=${date}&page=${page + 1}&size=${rowsPerPage}`);
        const responseJson = await response.json();
        if (response.ok) {
          const { items, totalCount } = responseJson.data;
          setItems(items);
          setTotalCount(totalCount);
        } else {
          throw new Error(responseJson.message);
        }
      }
    } finally {
      setLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    setLoading(true);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(0);
    setLoading(true);
  }, [date]);

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['moreTitle']}>
      <div className={classes.header}>
        <div className={classes.filter}>
          <TextField
            className={classes.datetimeField}
            variant="filled"
            color="filledTextFieldGreen"
            label={t('sharedDate')}
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            sx={{ minWidth: '33%' }}
          />
        </div>
      </div>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t('sharedIp')}</TableCell>
              <TableCell>{t('sharedTime')}</TableCell>
              <TableCell>{t('sharedHttpMethod')}</TableCell>
              <TableCell>{t('sharedRequestPath')}</TableCell>
              <TableCell>{t('sharedHttpStatusCode')}</TableCell>
              <TableCell>{t('sharedRequestUserAgent')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading ? items.map((item) => (
              <TableRow key={crypto.randomUUID()}>
                <TableCell>{item.ip}</TableCell>
                <TableCell>{formatTime(item.time)}</TableCell>
                <TableCell>{item.requestMethod}</TableCell>
                <TableCell className={classes.autoOverflowCell}>{item.requestPath}</TableCell>
                <TableCell>{item.responseStatusCode}</TableCell>
                <TableCell className={classes.autoOverflowCell}>{item.requestUserAgent}</TableCell>
              </TableRow>
            )) : (<TableShimmer columns={2} endAction />)}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage={t('labelRowsPerPage')}
          labelDisplayedRows={({ from, to, count }) => t('labelDisplayedRows', { from, to, count })}
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => { setPage(newPage); }}
          onRowsPerPageChange={(e) => {
            setRowPerPage(parseInt(e.target.value, 10));
            setPage(0);
          }}
        />
      </TableContainer>
    </PageLayout>
  );
};

export default ApiLogPage;
