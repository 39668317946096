import React, { useState } from 'react';
import {
  IconButton, Menu, MenuItem, useMediaQuery, useTheme,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import RemoveDialog from '../../common/components/RemoveDialog';
import { useTranslation } from '../../common/components/LocalizationProvider';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
  },
  iconButton: {
    color: 'inherit',
  },
}));

const CollectionActions = ({
  itemId, itemName, itemTypeName, editPath, removePath, endpoint, setTimestamp, customActions, readonly, onEdit,
  removeDialogTitle, removeDialogContent,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation();

  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [removing, setRemoving] = useState(false);

  const handleEdit = () => {
    if (onEdit) {
      onEdit(itemId);
    } else {
      navigate(`${editPath}/${itemId}`);
    }
    setMenuAnchorEl(null);
  };

  const handleRemove = () => {
    if (removePath) {
      navigate(removePath);
    } else {
      setRemoving(true);
    }
    setMenuAnchorEl(null);
  };

  const handleCustom = (action) => {
    action.handler(itemId);
    setMenuAnchorEl(null);
  };

  const handleRemoveResult = (removed) => {
    setRemoving(false);
    if (removed) {
      setTimestamp(Date.now());
    }
  };

  return (
    <>
      {phone ? (
        <>
          <IconButton className={classes.iconButton} size="small" onClick={(event) => setMenuAnchorEl(event.currentTarget)}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
          <Menu open={!!menuAnchorEl} anchorEl={menuAnchorEl} onClose={() => setMenuAnchorEl(null)}>
            {customActions && customActions.map((action) => (
              <MenuItem onClick={() => handleCustom(action)} key={action.key}>{action.title}</MenuItem>
            ))}
            {!readonly && [
              <MenuItem key="edit" onClick={handleEdit}>{t('sharedEdit')}</MenuItem>,
              <MenuItem key="delete" onClick={handleRemove}>{t('sharedDelete')}</MenuItem>,
            ]}
          </Menu>
        </>
      ) : (
        <div className={classes.row}>
          {customActions && customActions.map((action) => (
            <IconButton className={classes.iconButton} size="small" onClick={() => handleCustom(action)} key={action.key}>
              {action.icon}
            </IconButton>
          ))}
          {!readonly && (
            <>
              <IconButton className={classes.iconButton} size="small" onClick={handleEdit}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton className={classes.iconButton} size="small" onClick={handleRemove}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </>
          )}
        </div>
      )}
      <RemoveDialog
        open={removing}
        endpoint={endpoint}
        itemId={itemId}
        itemName={itemName}
        itemTypeName={itemTypeName}
        onResult={handleRemoveResult}
        onDisagree={() => setRemoving(false)}
        title={removeDialogTitle}
        content={removeDialogContent}
      />
    </>
  );
};

export default CollectionActions;
