import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import useReportStyles from '../common/useReportStyles';
import { groupsActions } from '../../store';
import SelectField from '../../common/components/SelectField';

const ColumnSelect = ({
  pageId, setColumns, columnsObject, disabled, inputFieldColor = 'filledTextFieldBlue',
}) => {
  const classes = useReportStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const groupSeletedColumns = useSelector((state) => state.groups.groupSeletedColumns);
  const handleDevices = (e) => {
    setColumns(e.target.value);
    if (pageId !== null) {
      dispatch(groupsActions.selectGroupColumns(e.target.value));
    }
  };
  return (
    <div className={classes.filterItem}>
      <SelectField
        fullWidth
        color={inputFieldColor}
        label={t('sharedColumns')}
        value={groupSeletedColumns}
        onChange={handleDevices}
        multiple
        disabled={disabled}
        data={Object.keys(columnsObject)}
        keyGetter={(it) => it}
        titleGetter={(it) => columnsObject[it].name}
      />
    </div>
  );
};

export default ColumnSelect;
