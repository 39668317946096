import {
  FormControl, ListItemIcon, ListItemText, MenuItem, TextField,
} from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank, Check } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useEffectAsync } from '../../reactHelper';
import { useTranslation } from './LocalizationProvider';

const SelectField = ({
  color,
  label,
  required,
  error,
  helperText,
  fullWidth,
  multiple,
  allControl,
  value,
  setValue,
  emptyValue = 0,
  emptyTitle = '\u00a0',
  onChange,
  endpoint,
  data,
  disableItems,
  keyGetter = (item) => item.id,
  valueGetter,
  titleGetter = (item) => item.name,
  customPropsGetter = (item) => (item.customProps),
}) => {
  const t = useTranslation();
  const [items, setItems] = useState(data);
  const SELECT_ALL_VALUE = '_all';

  valueGetter = valueGetter || keyGetter;

  useEffect(() => {
    setItems(data);
  }, [data]);

  useEffectAsync(async () => {
    if (endpoint) {
      const response = await fetch(endpoint);
      if (response.ok) {
        let responseItems = await response.json();
        if (disableItems) {
          responseItems = responseItems.filter((it) => !disableItems.includes(valueGetter(it)));
        }
        setItems(responseItems);
      } else {
        throw Error(await response.text());
      }
    }
  }, []);

  if (items) {
    // ensure value is in items list
    const isValidValue = multiple
      ? value?.every((v) => v === SELECT_ALL_VALUE || items.some((item) => valueGetter(item) === v))
      : items.some((item) => valueGetter(item) === value);
    const validValue = isValidValue ? value : (multiple ? [] : (emptyValue ?? ''));
    let selectProps = {
      value: validValue,
      onChange: (e, c) => {
        onChange(e, c);
        if (multiple && allControl) {
          const clickedValue = c.props.value;
          if (clickedValue === SELECT_ALL_VALUE) {
            setValue((prevValue) => {
              if (prevValue.includes(SELECT_ALL_VALUE)) {
                return [...items.map((item) => valueGetter(item)), SELECT_ALL_VALUE];
              }
              return [];
            });
          } else {
            setValue((prevValue) => {
              if (prevValue.includes(clickedValue)) {
                return prevValue;
              }
              return prevValue.filter((v) => v !== clickedValue);
            });
          }
        }
      },
      renderValue: (selected) => (multiple
        ? selected.filter((v) => v !== SELECT_ALL_VALUE).map((v) => titleGetter(items.find((item) => valueGetter(item) === v))).join(', ')
        : titleGetter(items.find((item) => valueGetter(item) === selected))
      ),
      MenuProps: { autoFocus: false },
    };
    if (multiple) {
      selectProps = { ...selectProps, multiple: true };
    }
    return (
      <FormControl fullWidth={fullWidth}>
        <TextField
          select
          variant="filled"
          color={color}
          label={label}
          required={required}
          error={error}
          helperText={helperText}
          SelectProps={selectProps}
        >
          {!multiple && emptyValue !== null && (
            <MenuItem value={emptyValue}>
              <ListItemIcon>
                {validValue === emptyValue && <Check color="primary" />}
              </ListItemIcon>
              <ListItemText>{emptyTitle}</ListItemText>
            </MenuItem>
          )}
          {multiple && allControl && (
            <MenuItem key={SELECT_ALL_VALUE} value={SELECT_ALL_VALUE}>
              {validValue.includes(SELECT_ALL_VALUE) ? (
                <>
                  <ListItemIcon>
                    <CheckBox color="primary" />
                  </ListItemIcon>
                  <ListItemText>{t('sharedCancelAll')}</ListItemText>
                </>
              ) : (
                <>
                  <ListItemIcon>
                    <CheckBoxOutlineBlank style={{ color: '#a1a1a1' }} />
                  </ListItemIcon>
                  <ListItemText>{t('sharedSelectAll')}</ListItemText>
                </>
              )}
            </MenuItem>
          )}
          {items.map((item) => (
            <MenuItem
              key={keyGetter(item)}
              value={valueGetter(item)}
              {...customPropsGetter(item)}
            >
              <ListItemIcon>
                {multiple && (
                  validValue.includes(valueGetter(item)) ? (
                    <CheckBox color="primary" />
                  ) : (
                    <CheckBoxOutlineBlank style={{ color: '#a1a1a1' }} />
                  )
                )}
                {!multiple && valueGetter(item) === validValue && (
                  <Check color="primary" />
                )}
              </ListItemIcon>
              <ListItemText>{titleGetter(item)}</ListItemText>
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    );
  }
  return null;
};

export default SelectField;
