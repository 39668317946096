import React, { useState } from 'react';
import moment from 'moment';
import {
  Button, TextField, Typography,
} from '@mui/material';
import { Download } from '@mui/icons-material';
import PageLayout from '../../common/components/PageLayout';
import { useTranslation } from '../../common/components/LocalizationProvider';
import SettingsMenu from '../components/SettingsMenu';
import useReportStyles from '../../other/common/useReportStyles';

const DeviceLogPage = () => {
  const classes = useReportStyles();
  const t = useTranslation();
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

  const handleDownload = () => {
    window.open(`/api/logs/device?day=${date.replace(/-/g, '')}`);
  };

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['moreTitle']}>
      <div className={classes.header}>
        <div className={classes.filter}>
          <TextField
            className={classes.datetimeField}
            variant="filled"
            color="filledTextFieldGreen"
            label={t('sharedDate')}
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            sx={{ minWidth: '33%' }}
          />
          <Button
            variant="contained"
            color="containedButtonGreen2"
            onClick={handleDownload}
          >
            <Download />
            <Typography noWrap>{t('sharedDownload')}</Typography>
          </Button>
        </div>
      </div>
    </PageLayout>
  );
};

export default DeviceLogPage;
