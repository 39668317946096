import React from 'react';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { prefixString } from '../../../common/util/stringUtils';
import SelectField from '../../../common/components/SelectField';

const SupportTicketStatusSelect = (props) => {
  const t = useTranslation();
  const ticketStatuses = ['pending', 'processing', 'resolved', 'closed'];

  return (
    <SelectField
      {...props}
      color="filledTextFieldGreen"
      label={t('sharedStatus')}
      helperText=" "
      data={ticketStatuses}
      keyGetter={(it) => it}
      titleGetter={(it) => (it ? t(prefixString('supportTicketStatus', it)) : '')}
      emptyValue={null}
    />
  );
};

export default SupportTicketStatusSelect;
