import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button, Typography,
  InputAdornment, TextField,
  IconButton,
  ListItemText, ListItemIcon, Chip,
} from '@mui/material';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import PageLayout from '../common/components/PageLayout';
import CardLayout from '../common/components/CardLayout';
import CardSection from '../common/components/CardSection';
import AccountMenu from './components/AccountMenu';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useCatch, useEffectAsync } from '../reactHelper';
import { errorsActions, groupsActions } from '../store';
import CardAccordion from '../common/components/CardAccordion';
import { filterNameByKeyword } from './components/SearchHeader';

const useStyles = makeStyles(() => ({
  toolbar: {
    marginLeft: '1.88rem',
    marginRight: '1.7rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  switchContainer: {
    marginLeft: '2.25rem',
    marginBottom: '0.25rem',
    marginRight: '1.65rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  accordionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    width: '3.75rem',
    height: '2rem',
  },
  alert: {
    position: 'fixed',
    zIndex: 10,
    left: '50%',
    bottom: '5%',
  },
}));

const GroupsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.session.user);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]); // group of manage
  const [joinItems, setJoinItems] = useState([]); // group of joined
  const [invitedItems, setInvitedItems] = useState([]); // group of invited
  const [keyword, setKeyword] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setIsSearching(false);
  }, []);

  useEffectAsync(async () => {
    const getMembers = await fetch('/api/groups-members/groups'); // get all members and admins by userId
    const getGroups = await fetch('/api/groups?all=true'); // get all groups
    if (getMembers.ok && getGroups.ok) {
      const members = await getMembers.json();
      const groups = await getGroups.json();
      const groupItems = members.map((member) => ({
        ...member,
        name: groups.filter((group) => group.id === member.groupId)[0]?.name, // Add null check
      }));
      // filter groupItems (this user && role is admin)
      const manageGroups = groupItems.filter((group) => (
        group.userId === currentUser.id && group.role === 'admin'
      ));
      setItems(manageGroups);
      // filter groupItems (this user && role is member)
      const joinedGroups = groupItems.filter((group) => (
        group.userId === currentUser.id && group.role === 'member'
      ));
      setJoinItems(joinedGroups);
    } else {
      throw Error(await getMembers.text());
    }
  }, [timestamp]);

  // get the groups of invited
  useEffectAsync(async () => {
    const getInvited = await fetch('/api/groups-invited/member');
    if (getInvited.ok) {
      const invitedGroups = await getInvited.json();
      setInvitedItems(invitedGroups);
    } else {
      throw Error(await getInvited.text());
    }
  }, [timestamp]);

  const onItemSaved = useCatch(async () => {
    const response = await fetch('/api/groups?all=true');
    if (response.ok) {
      const result = await response.json();
      dispatch(groupsActions.update(result));
    } else {
      throw Error(await response.text());
    }
  });

  // 創建群組
  const handleCreateGroup = useCatch(async () => {
    const response = await fetch('/api/groups?all=true', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: t('groupDefaultName') }),
    });

    if (response.ok) {
      const data = await response.json();
      onItemSaved(data);
      const setAdmin = await fetch(`/api/groups-members/member?groupId=${data.id}&userId=${currentUser.id}&role=admin`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
      if (setAdmin.ok) {
        navigate(`/accounts/group/${data.id}`);
      }
    } else {
      throw Error(await response.text());
    }
  });

  // 參加群組邀約
  const handleAttendGroup = useCatch(async (groupId) => {
    const setMember = await fetch(`/api/groups-members/member?groupId=${groupId}&userId=${currentUser.id}&role=member`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    });
    if (setMember.status === 400) {
      dispatch(errorsActions.push(t('invitedGroupIsNotExist')));
    }
    setTimestamp(Date.now());
  });

  // 取消群組邀約
  const handleCancelGroup = useCatch(async (groupId) => {
    setTimestamp(Date.now());
    await fetch(`/api/groups-invited/member?groupId=${groupId}&status=false`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    });
  });

  // 進去已加入的群組
  const handleHasjoinGroup = useCatch(async (groupId) => {
    const response = await fetch('/api/groups/joined');
    if (response.ok) {
      const data = await response.json();
      if (data.some((group) => group.id === groupId)) {
        navigate(`/accounts/group/${groupId}`);
      } else {
        dispatch(errorsActions.push(t('invitedGroupIsNotExist')));
        setTimestamp(Date.now());
      }
    } else {
      throw Error(await response.text());
    }
  });

  return (
    <PageLayout
      menu={<AccountMenu />}
      breadcrumbs={['settingsUser', 'groupMember']}
    >
      <CardLayout disableDivider>
        <CardSection
          header={t('groupTitle')}
          button={(
            <Button
              fullWidth
              variant="contained"
              color="containedButtonGreen2"
              onClick={handleCreateGroup}
            >
              {t('createNewGroup')}
            </Button>
          )}
        >
          <TextField
            variant="filled"
            color="filledTextFieldGreen"
            label={t('searchGroup')}
            helperText=" "
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
              setIsSearching(e.target.value !== '');
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography>{t('createNewGroupExplanation')}</Typography>
        </CardSection>
        <CardSection childrenSx={{ padding: 0 }}>
          <CardAccordion
            summary={t('manageMyGroups')}
            detailSx={{ gap: '1rem' }}
            searching={isSearching}
          >
            {items.filter(filterNameByKeyword(keyword)).map((item) => (
              <div className={classes.accordionContainer} key={item.groupId}>
                <ListItemIcon>
                  <Diversity3Icon color="primary" />
                </ListItemIcon>
                <ListItemText primary={item.name} />
                <IconButton onClick={() => { navigate(`/accounts/group/${item.groupId}`); }} edge="end" variant="grey">
                  <NavigateNextIcon />
                </IconButton>
              </div>
            ))}
          </CardAccordion>
          <CardAccordion
            summary={t('invitedGroups')}
            summaryRight={invitedItems.filter(filterNameByKeyword(keyword)).length > 0 && (
              <Chip
                color="chipGreen"
                label={t(`${invitedItems.filter(filterNameByKeyword(keyword)).length}`)}
                sx={{ height: 'fit-content', fontSize: '1rem' }}
              />
            )}
            detailSx={{ gap: '1rem' }}
            searching={isSearching}
          >
            {invitedItems.filter(filterNameByKeyword(keyword)).map((item) => (
              <div className={classes.accordionContainer} key={item.groupId}>
                <ListItemIcon>
                  <Diversity3Icon />
                </ListItemIcon>
                <ListItemText primary={item.name} />
                <Chip
                  label={t('groupAttend')}
                  color="chipGrey"
                  onClick={() => { handleAttendGroup(item.groupId); }}
                />
                <IconButton edge="end" onClick={() => { handleCancelGroup(item.groupId); }} variant="grey">
                  <HighlightOffIcon />
                </IconButton>
              </div>
            ))}
          </CardAccordion>
          <CardAccordion
            summary={t('hasJoined')}
            detailSx={{ gap: '1rem' }}
            searching={isSearching}
          >
            {joinItems.filter(filterNameByKeyword(keyword)).map((item) => (
              <div className={classes.accordionContainer} key={item.groupId}>
                <ListItemIcon>
                  <Diversity3Icon color="primary" />
                </ListItemIcon>
                <ListItemText primary={item.name} />
                <IconButton onClick={() => { handleHasjoinGroup(item.groupId); }} edge="end" variant="grey">
                  <NavigateNextIcon />
                </IconButton>
              </div>
            ))}
          </CardAccordion>
        </CardSection>
      </CardLayout>
    </PageLayout>
  );
};

export default GroupsPage;
