import React, { useEffect, useState } from 'react';
// import moment from 'moment';
import {
  MenuItem, InputAdornment,
  Button, Snackbar, IconButton,
  LinearProgress, TextField, Popover,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { signInWithEmailAndPassword, getMultiFactorResolver } from 'firebase/auth';
import { errorsActions, sessionActions } from '../store';
import { useLocalization, useTranslation } from '../common/components/LocalizationProvider';
import LandingLoginLayout from './LandingLoginLayout';
import FormContainer from './FormContainer';
import usePersistedState from '../common/util/usePersistedState';
import { handleLoginTokenListeners, nativePostMessage } from '../common/components/NativeInterface';
import { useCatch } from '../reactHelper';
import auth from '../firebase/auth';
import ThirdPartyAuthContainer from './ThirdPartyAuthContainer';
import Policy from './Policy';
import LoginVerification from './LoginVerification';
import useQuery from '../common/util/useQuery';
import LoginBackgroundImage from '../resources/images/login-bg.jpg';
import ATrackLogoBlue from '../resources/images/ATrackLogo_Blue.png';

const useStyles = makeStyles((theme) => ({
  options: {
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  // title: {
  //   fontWeight: 700,
  //   fontSize: '2.25rem',
  // },
  logo: {
    width: '50%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.625rem',
    marginTop: '1rem',
  },
  textBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '6.25rem',
    marginTop: '2rem',
  },
  textBtn: {
    color: theme.palette.colors.white,
  },
  policyContainer: {
    display: 'flex',
    marginTop: '1.25rem',
  },
  languageContainer: {
    position: 'float',
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation();
  const location = useLocation();
  const query = useQuery();

  const { languages, setLanguage } = useLocalization();
  const languageList = Object.entries(languages).map((values) => ({ code: values[0], name: values[1].name }));

  const [failed, setFailed] = useState(false);

  const [email, setEmail] = usePersistedState('loginEmail', '');
  const [password, setPassword] = useState('');

  // const registrationEnabled = useSelector((state) => state.session.server.registration);
  const languageEnabled = useSelector((state) => !state.session.server.attributes['ui.disableLoginLanguage']);
  // const emailEnabled = useSelector((state) => state.session.server.emailEnabled);
  // const openIdEnabled = useSelector((state) => state.session.server.openIdEnabled);
  const openIdForced = useSelector((state) => state.session.server.openIdEnabled && state.session.server.openIdForce);
  const googleLoginEnabled = useSelector((state) => state.session.server.googleLoginEnabled || true);
  const facebookLoginEnabled = useSelector((state) => state.session.server.facebookLoginEnabled || false);
  const appleLoginEnabled = useSelector((state) => state.session.server.appleLoginEnabled || true);

  const [announcementShown, setAnnouncementShown] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setFailed(false);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setFailed(false);
  };

  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const handleLanguageAnchorClick = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setLanguageAnchorEl(null);
  };

  const handleLanguageSelect = (languageCode) => {
    setLanguage(languageCode);
    setLanguageAnchorEl(null);
  };

  const announcement = useSelector((state) => state.session.server.announcement);

  const [isVerifying, setIsVerifying] = useState(false);
  const [multiFactorResolver, setMultiFactorResolver] = useState();

  // const generateLoginToken = async () => {
  //   if (nativeEnvironment) {
  //     let token = '';
  //     try {
  //       const expiration = moment().add(6, 'months').toISOString();
  //       const response = await fetch('/api/session/token', {
  //         method: 'POST',
  //         body: new URLSearchParams(`expiration=${expiration}`),
  //       });
  //       if (response.ok) {
  //         token = await response.text();
  //       }
  //     } catch (error) {
  //       token = '';
  //     }
  //     nativePostMessage(`login|${token}`);
  //   }
  // };

  // const handlePasswordLogin = async (event) => {
  //   event.preventDefault();
  //   try {
  //     const response = await fetch('/api/session', {
  //       method: 'POST',
  //       body: new URLSearchParams(`email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`),
  //     });
  //     if (response.ok) {
  //       const user = await response.json();
  //       generateLoginToken();
  //       dispatch(sessionActions.updateUser(user));
  //       navigate('/');
  //     } else {
  //       throw Error(await response.text());
  //     }
  //   } catch (error) {
  //     setFailed(true);
  //     setPassword('');
  //   }
  // };

  const getRedirectUrl = (provider) => {
    const next = query.get('next');
    const qs = new URLSearchParams({ });
    if (next) {
      qs.set('next', next);
    }
    if (provider) {
      qs.set('provider', provider);
    }
    return `/login/redirect${qs.toString() ? `?${qs.toString()}` : ''}`;
  };

  const handlePasswordLoginWithMfa = useCatch(async (event) => {
    event.preventDefault();
    try {
      dispatch(errorsActions.clear());
      await signInWithEmailAndPassword(auth, email, password);
      navigate(getRedirectUrl());
    } catch (error) {
      const errorCode = error.code;
      if (errorCode === 'auth/multi-factor-auth-required') {
        const resolver = getMultiFactorResolver(auth, error);
        setMultiFactorResolver(resolver);
      } else if (error.code === 'auth/invalid-credential' || error.code === 'auth/invalid-login-credentials' || errorCode === 'auth/user-not-found' || errorCode === 'auth/wrong-password') {
        // @firebase/auth < 1.5.0 throws 'auth/invalid-login-credentials', >= 1.5.0 throws 'auth/invalid-credential' (if email-enumeration-protection is enabled)
        // firebase-emulator throws 'auth/wrong-password'
        // see: https://github.com/firebase/firebase-js-sdk/pull/7772
        // see: https://cloud.google.com/identity-platform/docs/admin/email-enumeration-protection
        setFailed(true);
        setPassword('');
      } else if (errorCode === 'auth/too-many-requests') {
        throw new Error(t('sharedTooManyRequestErrorMessage'));
      } else {
        throw new Error();
      }
    } finally {
      setPassword('');
    }
  });

  const handleTokenLogin = useCatch(async (token) => {
    const response = await fetch(`/api/session?token=${encodeURIComponent(token)}`);
    if (response.ok) {
      const user = await response.json();
      dispatch(sessionActions.updateUser(user));
      navigate('/');
    } else {
      throw Error(await response.text());
    }
  });

  const handleSpecialKey = (e) => {
    if (e.keyCode === 13 && email && password) {
      handlePasswordLoginWithMfa(e);
    }
  };

  const handleOpenIdLogin = () => {
    document.location = '/api/session/openid/auth';
  };

  const handleEmailRegister = () => {
    dispatch(errorsActions.clear());
    navigate('/register');
  };

  const handle3rdPartyLogin = useCatch(async (provider) => {
    if (auth.currentUser) {
      await auth.signOut();
    }
    navigate(getRedirectUrl(provider));
  });

  const handleForgotPassword = async () => {
    navigate('/reset-password');
  };

  const onVerificationCodeVerifySuccess = () => {
    navigate('/login/redirect');
  };

  const onVerificationCodeResendFail = (error) => {
    if (error.code === 'auth/too-many-requests') {
      dispatch(errorsActions.push(t('sharedTooManyRequestErrorMessage')));
    } else if (error.code === 'auth/invalid-user-token' || error.code === 'auth/requires-recent-login') {
      dispatch(errorsActions.push(t('twoFactorAuthenticationErrorMessageReEnterPassword')));
      setPassword('');
      setIsVerifying(false);
    } else {
      throw new Error();
    }
  };

  useEffect(() => nativePostMessage('authentication'), []);

  useEffect(() => {
    const listener = (token) => handleTokenLogin(token);
    handleLoginTokenListeners.add(listener);
    return () => handleLoginTokenListeners.delete(listener);
  }, []);

  // wait multiFactorResolver to be set and switch to verify form
  useEffect(() => {
    if (multiFactorResolver) {
      setIsVerifying(true);
    }
  }, [multiFactorResolver]);

  if (openIdForced) {
    handleOpenIdLogin();
    return (<LinearProgress />);
  }

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email);
    }
  }, []);

  return (
    <LandingLoginLayout
      header={false}
      style={{
        backgroundImage: `url(${LoginBackgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        height: '100%',
        minHeight: '750px',
      }}
    >
      {isVerifying ?
        (
          <LoginVerification
            onGoBack={() => setIsVerifying(false)}
            multiFactorResolver={multiFactorResolver}
            onVerificationCodeVerifySuccess={onVerificationCodeVerifySuccess}
            onVerificationCodeResendFail={onVerificationCodeResendFail}
          />
        ) : (
          <>
            {/* <div className={classes.options}>
              {nativeEnvironment && (
                <Tooltip title={t('settingsServer')}>
                  <IconButton onClick={() => navigate('/change-server')}>
                    <LockOpenIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div> */}
            <FormContainer
              header={
                <img src={ATrackLogoBlue} className={classes.logo} alt="ATrack Logo" />
              }
              footer={(
                <>
                  <ThirdPartyAuthContainer
                    pageStyle="login"
                    titleText={t('thirdPartyLoginInfo')}
                    googleLoginEnabled={googleLoginEnabled}
                    facebookLoginEnabled={facebookLoginEnabled}
                    appleLoginEnabled={appleLoginEnabled}
                    handle3rdPartyLogin={handle3rdPartyLogin}
                  />
                  <div className={classes.textBtnContainer}>
                    { /** Forgot password */}
                    <Button
                      variant="text"
                      onClick={handleForgotPassword}
                      className={classes.textBtn}
                    >
                      {t('loginForgotPassword')}
                    </Button>
                    { /** Change language */}
                    <div>
                      <Button
                        variant="text"
                        disabled={!languageEnabled}
                        onClick={handleLanguageAnchorClick}
                        aria-describedby="language-menu"
                        className={classes.textBtn}
                      >
                        {t('loginChangeLanguage')}
                      </Button>
                      <Popover
                        id="language-menu"
                        open={Boolean(languageAnchorEl)}
                        anchorEl={languageAnchorEl}
                        onClose={handleLanguageMenuClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        {languageList.map((it) => <MenuItem key={it.code} onClick={() => handleLanguageSelect(it.code)}>{it.name}</MenuItem>)}
                      </Popover>
                    </div>
                  </div>
                  <div className={classes.policyContainer}>
                    <Policy pageStyle="login" />
                  </div>
                </>
              )}
            >
              <TextField
                label={t('email')}
                required
                variant="filled"
                color="filledTextFieldLogin"
                error={failed}
                name="email"
                value={email}
                autoComplete="email"
                autoFocus={!email}
                onChange={handleEmailChange}
                onKeyUp={handleSpecialKey}
                helperText={failed ? t('invalid email or password') : ' '}
              />
              <TextField
                label={t('userPassword')}
                required
                variant="filled"
                color="filledTextFieldLogin"
                error={failed}
                name="password"
                value={password}
                type={showPassword ? 'text' : 'password'}
                autoComplete="password"
                autoFocus={!!email}
                onChange={handlePasswordChange}
                onKeyUp={handleSpecialKey}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        tabIndex={-1}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className={classes.buttonContainer}>
                { /** Email login button */ }
                <Button
                  onClick={handlePasswordLoginWithMfa}
                  onKeyUp={handleSpecialKey}
                  variant="contained"
                  color="containedButtonGreen"
                  disabled={!email || !password}
                >
                  {t('loginLogin')}
                </Button>
                { /** Email register button */ }
                <Button
                  onClick={handleEmailRegister}
                  variant="contained"
                  startIcon={<EmailIcon />}
                  color="containedButtonGrey"
                >
                  {t('emailRegister')}
                </Button>
              </div>
            </FormContainer>

            <Snackbar
              open={!!announcement && !announcementShown}
              message={announcement}
              action={(
                <IconButton size="small" color="inherit" onClick={() => setAnnouncementShown(true)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}
            />
          </>
        )}
    </LandingLoginLayout>
  );
};

export default LoginPage;
