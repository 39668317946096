import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Chip, Stack, Switch, Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { useEffectAsync } from '../../reactHelper';
import { useTranslation } from './LocalizationProvider';
import { useAttributePreference } from '../util/preferences';
import StyledTableHeadCell from './StyledTableHeadCell';
import TableShimmer from './TableShimmer';
import { prefixString } from '../util/stringUtils';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingLeft: theme.spacing(18),
  },
}));

const NotificationSettingsTable = ({
  endpoint,
}) => {
  const classes = useStyles();
  const t = useTranslation();

  const hiddenTypes = useAttributePreference('report.eventsHiddenTypes', '').split(',');

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [allNotificators, setAllNotificators] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [userNotifications, setUserNotifications] = useState([]);

  const updateNotification = async (targetType, targetNotificator = null) => {
    const userNotification = userNotifications.find((userNotification) => userNotification.type === targetType);
    let body = {};
    let url = `/api/${endpoint}`;
    let method;
    if (!userNotification) {
      // add new notification
      method = 'POST';
      body = {
        type: targetType,
        notificators: targetNotificator || allNotificators.join(','),
        always: true,
      };
    } else {
      url += `/${userNotification.id}`;
      let notificators = userNotification.notificators.split(',');
      if (targetNotificator) {
        if (notificators.includes(targetNotificator)) {
          notificators = notificators.filter((it) => it !== targetNotificator);
        } else {
          notificators.push(targetNotificator);
        }
      } else {
        notificators = [];
      }
      if (notificators.length) {
        // update existing notification
        method = 'PUT';
        body = userNotification;
        body.notificators = notificators.join(',');
        body.always = true;
      } else {
        // remove existing notification
        method = 'DELETE';
      }
    }
    const response = await fetch(url, {
      method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw Error(await response.text());
    }
    if (method === 'DELETE') {
      return null;
    }
    return response.json();
  };

  const handleNotificatorClick = async (targetType, targetNotificator) => {
    const newUserNotification = await updateNotification(targetType, targetNotificator);
    const newUserNotifications = userNotifications.filter((item) => item.type !== targetType);
    if (newUserNotification) {
      newUserNotifications.push(newUserNotification);
    }
    setUserNotifications(newUserNotifications);
  };

  const handleNotificationSwitchChange = async (targetType) => {
    const newUserNotification = await updateNotification(targetType);
    const newUserNotifications = userNotifications.filter((item) => item.type !== targetType);
    if (newUserNotification) {
      newUserNotifications.push(newUserNotification);
    }
    setUserNotifications(newUserNotifications);
  };

  const handleNotificationSwitchAllChange = async () => {
    if (items.every((item) => item.notificators.length > 0)) {
      // remove all notifications
      const response = await fetch(`/api/${endpoint}/all`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) {
        throw Error(await response.text());
      } else {
        setUserNotifications([]);
      }
    } else {
      // add all notifications
      const response = await fetch(`/api/${endpoint}/all`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) {
        throw Error(await response.text());
      } else {
        const json = await response.json();
        setUserNotifications(json.data);
      }
    }
  };

  useEffectAsync(async () => {
    setLoading(true);
    const response = await fetch('/api/notifications/notificators');
    if (response.ok) {
      const responseItems = await response.json();
      const responseList = responseItems.map((item) => item.type);
      setAllNotificators(responseList);
    } else {
      throw Error(await response.text());
    }
  }, []);

  useEffectAsync(async () => {
    setLoading(true);
    const response = await fetch('/api/notifications/types');
    if (response.ok) {
      let responseItems = await response.json();
      if (hiddenTypes) {
        responseItems = responseItems.filter((item) => !hiddenTypes.includes(item.type));
      }
      setNotificationTypes(responseItems);
    } else {
      throw Error(await response.text());
    }
  }, []);

  useEffectAsync(async () => {
    setLoading(true);
    const response = await fetch(`/api/${endpoint}`);
    if (response.ok) {
      const responseItems = await response.json();
      setUserNotifications(responseItems);
    } else {
      throw Error(await response.text());
    }
  }, []);

  useEffect(() => {
    if (allNotificators.length && notificationTypes.length) {
      const newItems = notificationTypes.map((item) => {
        const userNotification = userNotifications.find((userNotification) => userNotification.type === item.type);
        return {
          id: userNotification?.id,
          key: item.type,
          type: t(item.type),
          // threshold: userNotification?.attributes.threshold,
          notificators: userNotification?.notificators.split(',') || [],
        };
      });
      setItems(newItems);
      setLoading(false);
    }
  }, [allNotificators, notificationTypes, userNotifications]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableHeadCell className={classes.columnAction} />
          <StyledTableHeadCell>{t('eventNotificationType')}</StyledTableHeadCell>
          {/* <StyledTableHeadCell>{t('notificationThreshold')}</StyledTableHeadCell> */}
          <StyledTableHeadCell>{t('notificationActivatedNotificationChannel')}</StyledTableHeadCell>
          <StyledTableHeadCell>{t('notificationAppPushNotification')}</StyledTableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key="_all">
          <TableCell>
            <Switch
              sx={{ ml: 2 }}
              checked={items.every((item) => item.notificators.length > 0)}
              onChange={() => handleNotificationSwitchAllChange()}
            />
          </TableCell>
          <TableCell>{t('notificationSelectAll')}</TableCell>
        </TableRow>
        {!loading ? items.map((item) => (
          <TableRow key={item.key}>
            <TableCell>
              <Switch
                sx={{ ml: 2 }}
                checked={item.notificators.length > 0}
                onChange={() => handleNotificationSwitchChange(item.type)}
              />
            </TableCell>
            <TableCell>{t(prefixString('event', item.type))}</TableCell>
            {/* <TableCell>{item.threshold}</TableCell> */}
            <TableCell>
              <Stack direction="row" spacing={1}>
                {allNotificators.filter((notificator) => notificator !== 'firebase').map((notificator) => (
                  <Chip
                    key={notificator}
                    label={t(prefixString('notificator', notificator))}
                    color="chipBlue"
                    onClick={() => handleNotificatorClick(item.type, notificator)}
                    variant={item.notificators.includes(notificator) ? 'filled' : 'outlined'}
                  />
                ))}
              </Stack>
            </TableCell>
            <TableCell>
              {allNotificators.includes('firebase') && (
                <Chip
                  key="firebase"
                  label={t(prefixString('notificator', 'app'))}
                  color="chipBlue"
                  onClick={() => handleNotificatorClick(item.type, 'firebase')}
                  variant={item.notificators.includes('firebase') ? 'filled' : 'outlined'}
                />
              )}
            </TableCell>
          </TableRow>
        )) : (<TableShimmer columns={5} endAction />)}
      </TableBody>
    </Table>
  );
};

export default NotificationSettingsTable;
